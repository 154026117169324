import React from 'react';

import { ToastContainer } from 'react-toastify';

import './App.css';
import Router from './router/index';

const App = () => {
  return (
    <>
      <Router />
      <ToastContainer closeButton={false} />
    </>
  );
};

export default App;
