const routes = {
  inbox: '/inbox',
  login: '/',
  DASHBOARD_AESA: '/dashboard-aesa',
  DASHBOARD_TP_BANK: '/dashboard-tp-bank',
  forget_password: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SUPER_ADMIN: '/user-management/superAdmin',
  MANAGER_MANAGE: '/user-management/manager',
  OPERATOR_MANAGE: '/user-management/operator',
  user: '/admin/user',
  managerDetails: '/user-management/manager-details',
  operatorDetails: '/user-management/operator-details',
  ADD_MANAGER: '/user-management/add-manager',
  ADD_OPERATOR: '/user-management/add-operator',
  userProfilePath: '/user-profile',
  CREATE_PASSWORD: '/create-password',
  EDIT_MANAGER: '/edit-manager',
  EDIT_OPERATOR: '/edit-operator',
  TP_BANK_SUPER_ADMIN: '/user-management/tpbank-super-admin',
  TP_BANK_MANAGER: '/user-management/tpbank-manager',
  TP_BANK_OPERATOR: '/user-management/tpbank-operator',
  TP_BANK_USER_DETAILS: '/user-management/tpbank-user-details',
  CHANGE_PASSWORD: '/change-password',
  TP_BANK: '/tp-bank',
  TP_BANK_Detail: '/tp-bank/tpbank-details',
  ADD_SUPER_ADMIN: '/user-management/add-super-admin',
  EDIT_SUPER_ADMIN: '/edit-super-admin',
  ADD_TP_BANK: '/tp-bank/add',
  ADD_NOTIFICATION: '/manage-notifications/add',
  EDIT_TP_BANK: '/tp-bank/edit',
  CREATE_CASE: '/create-case',
  CREATE_CASE_DETAIL: '/create-case-detail',
  CREATE_CASE_MANAGER: '/create-case-manager',
  CREATE_CASE_OPERATOR: '/create-case-operator',
  CASE_INBOX: '/case-inbox',
  CORE_CASE: '/core-case',
  CASE_INBOX_SENT: '/case-inbox/sent',
  CASE_INBOX_RECEIVED: '/case-inbox/received',
  CASE_INBOX_CLOSED: '/case-inbox/closed',
  CASE_EDIT_MANUAL: '/case-edit-manual',
  MANAGE_NOTIFICATIONS: '/manage-notifications',
  MANAGE_TEMPLATES: '/manage-templates',
  TEMPLATES_DETAIL: '/template-detail',
  EDIT_TEMPLATE: '/manage-templates/edit',
  AUDIT_LOG: '/audit-log',
  CONTACT_US: '/contact-us',
  SEARCH_IN_CORE_SYSTEM: '/search-in-core-system',
  TRANSACTION_DETAILS: '/transaction-details',
  AUTH_TRANSACTION_DETAILS: '/auth-transaction-details',
  ARCHIVED_TP_BANKS: '/archived-tp-banks',
  ARCHIVED_TP_BANK_DETAILS: '/archived-tp-banks/tpbank-details',
};

export default routes;
