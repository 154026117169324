import colorConstant from '../../constants/colorConstant';

const styles = {
  mainHeaderContainer: {
    height: '5.972vw',
    // width: "20%",
    position: 'sticky',
    top: 0,
    zIndex: 3,
    padding: '0 2.917vw',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colorConstant.amexWhite,
    flexGrow: 1,
    alignItems: 'center',
    boxShadow: '0px 3px 17px rgba(0, 0, 0, 0.1);',
  },
  logoHeadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: '17.361vw',
  },
  headerLogo: {
    width: '9.028vw',
  },

  headerTextStyle: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '1.528vw',
    lineHeight: '1.250vw',
    marginLeft: '8.611vw',
  },

  notificationLogo: {
    width: '3.333vw',
  },
  profileText: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '0.972vw',
    lineHeight: '1.250vw',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden !important',
      maxWidth: '6.333vw',
      textOverflow: 'ellipsis',
    },
  },
  userMenuFont: {
    fontFamily: 'BentonSans',
    fontWeight: 550,
    fontSize: '0.972vw',
    lineHeight: '1.250vw',
    padding: '0.694vw',
    '& svg': {
      marginRight: '0.938vw',
    },
  },
  logoutContaner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '1.389vw 1.667vw',
    width: '27.014vw',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colorConstant.amexWhite,
    borderRadius: '0.556vw',
    '& svg': {
      fontSize: '5.556vw',
      color: colorConstant.alertRed,
    },
  },
  logoutHeading: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '1.528vw',
    lineHeight: '1.667vw',
    margin: '2.222vw 0 0.694vw 0',
  },
  logoutText: {
    fontFamily: 'BentonSans',
    fontWeight: 550,
    fontSize: '1.042vw',
    textAlign: 'center',
    lineHeight: '1.806vw',
    color: '#3A5263',
    opacity: 0.8,
    marginBottom: '28px',
  },
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    ' & button': {
      width: '10.278vw',
    },
  },
  popoverNotContainer: {
    backgroundColor: 'var(--white)',
    width: '400px',
    // maxHeight: '80vh',
    '& > div': {
      '&:first-child': {
        font: 'normal normal normal 14px/21px Roboto',
        letterSpacing: '0.08px',
        color: '#515151',
        padding: '16px',
      },
    },
  },
  clearAllDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clearAll: {
    font: 'normal normal normal 12px/15px Inter',
    color: '#1E88E5',
    cursor: 'pointer',
  },
  dividerStyl: {
    backgroundColor: 'var(--lite-border)',
  },
  notification: {
    borderBottom: '1px solid #EAEAEA',
    font: 'normal normal normal 12px/21px Roboto',
    letterSpacing: '0.07px',
    color: '#000000',
    display: 'flex',
    padding: '8px 16px',
    '& svg': {
      color: '#1E88E5',
      fontSize: '10px',
    },
    '& > div': {
      '&:last-child': {
        paddingLeft: '8px',
        '& > div': {
          '&:first-child': {
            flex: 0.8,
            color: '#515151',
            font: 'normal normal bold 14px/21px Roboto',
            letterSpacing: '0.08px',
          },
          '&:last-child': {
            flex: 0.2,
            color: '#868686',
          },
        },
      },
    },
  },
  readMore: {
    color: '#1E88E5',
    fontSize: 12,
    textAlign: 'center',
    cursor: 'pointer',
    padding: '12px 0',
  },
  createdDt: {
    font: 'normal normal normal 12px/21px Roboto',
    letterSpacing: '0.07px',
    color: '#868686',
  },
  deleteNotification: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 0.1,

    '& svg': {
      color: 'red',
      cursor: 'pointer',
      fontSize: '16px',
    },
  },
  noRecords: {
    textAlign: 'center',
    padding: '5px',
    font: 'normal normal normal 14px/21px Roboto',
    letterSpacing: '0.08px',
    color: '#515151',
  },
  readMoreBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '14px',
    lineHeight: '15px',
    color: '#515151',
    fontFamily: 'Inter',
    textTransform: 'capitalize',
  },
  description: {
    display: 'flex',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#1A202D',
    opacity: '0.8',
    padding: '5px 0 0 0',
    maxWidth: '300px',
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },
  otherInfoContainer: {
    display: 'flex',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#868686',
    fontFamily: 'Roboto',
    opacity: '0.8',
    padding: '5px 0 0 0',
    textTransform: 'capitalize',
  },
  notificationText: {
    font: 'normal normal normal 14px/17px Inter',
    color: '#515151',
    textTransform: 'capitalize',
  },
  internalNotificationBox: { minHeight: '80px', maxHeight: '60vh', overflowY: 'scroll' },
};

export default styles;
