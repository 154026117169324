import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class TransactionDetailsState {
  type: number = 1;
  listingType: number = 2;

  listType: number = 1;
  caseListingData = [];
  caseTransactionListing = [];
  caseDetails = {};
  caseListCount: number = 0;
  caseListPage: number = 1;
  caseListLimit: number = 10;
  caseListSearch: string = '';
  caseListPageCount: number = 1;

  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  bankCode: string = '';
  authCode: string = '';
  cardNumber: string = '';
  isFilter: boolean = false;
  currentSortKey = 'createdAt';
  isCurrentSortAscending = true;

  // Export file
  isCountValid: boolean = true;
}

export const TransactionDetailsReducer = (
  state: TransactionDetailsState = new TransactionDetailsState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.TRANSACTION_DETAILS) {
    return { ...state, ...action.payload };
  }
  return state;
};
