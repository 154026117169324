import * as Yup from 'yup';

const passwordError =
  'Password must be between 8-40 characters, it must have 1 Uppercase(A-Z), 1 lowercase (a-z), 1 number (0-9), and a special character except space';
const passRegrex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.* ).{8,40}$/;

const amountRegex = /^\d{0,1000}(\.\d{1,2})?$/;

/**
 * Sign in Form Validation Schema
 */
const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Please enter the required field')
      .email('Please enter a valid email address'),
    password: Yup.string()
      .required('Please enter the required field')
      .matches(passRegrex, passwordError),
  });

/**
 * Forget Password Form Validation Schema
 */
const ForgetPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
  });

/**
 * Reset Password Form Validation Schema
 */
const ResetPassword = () => {
  return Yup.object().shape({
    newPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError),
    confirmPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError)
      .when('newPass', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPass')], 'Password doesn’t match'),
      }),
  });
};

/**
 * Reset Password Form Validation Schema
 */
const CreatePassword = () => {
  return Yup.object().shape({
    newPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError),
    confirmPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError)
      .when('newPass', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPass')], 'Password doesn’t match'),
      }),
    passCode: Yup.string()
      .required('Please fill in the required field')
      .matches(/^\d{6}$/, 'Passcode should have 6 digits'),
  });
};

/**
 * Add a Manager Form Validation Schema
 */
const AddManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
    passCode: Yup.string()
      .required('Please fill in the required field')
      .matches(/^\d{6}$/, 'Passcode should have 6 digits'),
  });

/**
 * Add a Manager Form Validation Schema
 */
const EditManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
  });

/**
 * Add a Manager Form Validation Schema
 */
const UserProfileSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
  });

/**
 * Change Password Validation Schema
 */
const ChangePasswordSchema = () => {
  return Yup.object().shape({
    oldPassword: Yup.string().trim().required('Old Password is required'),
    newPassword: Yup.string()
      .trim()
      .required('New password is required')
      .matches(passRegrex, passwordError)
      .test('passwords-match', 'New password same as old password', function passwordMatch(value) {
        return this.parent.oldPassword !== value;
      }),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm password is required')
      .matches(passRegrex, passwordError)
      .test('passwords-match', 'New password same as old password', function passwordMatch(value) {
        return this.parent.oldPassword !== value;
      })
      .when('newPassword', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPassword')], 'Both password doesn’t match'),
      }),
  });
};

/**
 * Add a Super Admin Form Validation Schema
 */
const AddSuperAdminSchema = () =>
  Yup.object().shape({
    bank: Yup.string().trim().required('Please fill in the required field'),
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
    passCode: Yup.string()
      .required('Please fill in the required field')
      .matches(/^\d{6}$/, 'Passcode should have 6 digits'),
  });

const EditSuperAdminSchema = () =>
  Yup.object().shape({
    bank: Yup.string().trim().required('Please fill in the required field'),
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
  });

const AddTPBankSchema = () =>
  Yup.object().shape({
    tpBankName: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphabets are allowed for this field')
      .required('Please fill in the required field'),
    tpBankId: Yup.string()
      .trim()
      .matches(/^[0-9]{10}(?<!0000000000)$/, 'Only 10 digit numbers are allowed for this field')
      .required('Please fill in the required field'),
  });

/**
 * Add a Super Admin Form Validation Schema
 */
const AddCaseSchema = () =>
  Yup.object().shape({
    transDate: Yup.string().required('Please fill in the required field').nullable(),
    cardNumber: Yup.string()
      .trim()
      .matches(/^[0-9x]{15}$/, 'Only 15 digit numbers are allowed for this field')
      .required('Please fill in the required field')
      .nullable(),
    seName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9 ]+$/, 'SE Name can only be alpha numeric')
      .required('Please fill in the required field'),
    transAmount: Yup.string()
      .trim()
      .matches(amountRegex, 'Please enter valid amount upto 2 decimal places')
      .max(15, 'transaction amount must not exceed 15 digits')
      .required('Please fill in the required field'),
    merchantCode: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Merchant code must be a 4 digit number')
      .max(4, 'Merchant code must be a 4 digit number')
      .nullable(),
    retailerId: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Retailer ID must be digits')
      // .max(4, 'Merchant code must be a 4 digit number')
      .nullable(),
    presentmentAmount: Yup.string()
      .trim()
      .matches(amountRegex, 'Please enter valid amount upto 2 decimal places')
      .max(15, 'peresentment amount must not exceed 15 digits')
      .required('Please fill in the required field'),
    aprCode: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, 'Approval code can be alpha numeric')
      .required('Please fill in the required field'),
    terminalNo: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, 'Enter valid terminal number')
      .max(8, 'Terminal number cannot exceed 8 characters')
      .min(8, 'Terminal number must be of 8 characters')
      .nullable(),
    transId: Yup.string()
      .min(1, 'Transaction id must have atleast 1 digit')
      .max(25, 'Transaction id cannot exceed 25 digits')
      .nullable(),
    transType: Yup.string().trim().required('Please select the required field'),
    retrievalReason: Yup.string()
      .trim()
      .when(['tabValue', 'isEdit'], {
        is: (tabValue: any, isEdit: any) => tabValue === 0 && !isEdit,
        then: Yup.string().required('Please fill in the required field'),
      }),
    docType: Yup.string().trim(),
    cbReason: Yup.string()
      .trim()
      .when(['tabValue', 'isEdit'], {
        is: (tabValue: any, isEdit: any) => tabValue === 1 && !isEdit,
        then: Yup.string().required('Please fill in the required field'),
      }),
    cbAmt: Yup.string()
      .trim()
      .when(['tabValue', 'isEdit'], {
        is: (tabValue: any, isEdit: any) => tabValue === 1 && !isEdit,
        then: Yup.string()
          .matches(amountRegex, 'Please enter valid amount upto 2 decimal places')
          .max(15, 'Amount must not exceed 15 digits')
          .required('Please fill in the required field'),
      }),
    finalCb: Yup.string()
      .trim()
      .when(['tabValue', 'isEdit'], {
        is: (tabValue: any, isEdit: any) => tabValue === 2 && !isEdit,
        then: Yup.string().required('Please fill in the required field'),
      }),
    
  });

const retrievalSchema = () =>
  Yup.object().shape({
    retrievalReason: Yup.string().required('Please fill in the required field'),
    docType: Yup.string(),
  });

const FirstSchema = () =>
  Yup.object().shape({
    cbReason: Yup.string().required('Please fill in the required field'),
    cbAmt: Yup.string()
      .max(15, 'Amount must not exceed 15 digits')
      .matches(amountRegex, 'Please enter valid amount upto 2 decimal places'),
  });

const FinalSchema = () =>
  Yup.object().shape({
    finalCb: Yup.string().required('Please fill in the required field'),
    finalCbAmt: Yup.string()
      .max(15, 'Amount must not exceed 15 digits')
      .matches(amountRegex, 'Please enter valid amount upto 2 decimal places'),
  });

const AddNotificationSchema = () =>
  Yup.object().shape({
    selectAudience: Yup.string().trim().required('Please fill in the required field'),
    notificationTitle: Yup.string()
      .trim()
      .min(1, 'There should be atleast 1 Character for this field')
      .max(25, 'Field must not exceed 25 characters')
      .required('Please fill in the required field'),
    sendTo: Yup.array().min(1, 'Please select atleast one option  '),
    discription: Yup.string()
      .trim()
      .min(1, 'There should be atleast 1 Character for this field')
      .max(250, 'Field must not exceed 250 characters')
      .required('Please fill in the required field'),
    sendToId: Yup.array(),
    isEmail: Yup.boolean(),
  });

const EditTemplateSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('Please fill in required field').trim(),
    notResponded: Yup.string()
      .required('Please fill in required field')
      .test('len', 'Must be less than 3 digits', (val: any) => val.length <= 3),
    description: Yup.string().required('Please fill in required field').trim(),
  });

const FormValidation = {
  LoginSchema,
  ForgetPasswordSchema,
  ResetPassword,
  AddManagerSchema,
  ChangePasswordSchema,
  AddSuperAdminSchema,
  AddTPBankSchema,
  AddCaseSchema,
  retrievalSchema,
  FirstSchema,
  FinalSchema,
  AddNotificationSchema,
  EditTemplateSchema,
  CreatePassword,
  UserProfileSchema,
  EditManagerSchema,
  EditSuperAdminSchema,
};

export default FormValidation;
