import { createTheme } from '@mui/material';

const lightTheme = createTheme({
  // customizing for ltlabs
  palette: {
    primary: {
      main: '#3C659E',
      light: 'rgb(82,54,78)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      light: 'rgb(82,54,78)',
      contrastText: '#ffffff',
    },
  },
});

export default lightTheme;
