import showAlert from '../../components/alert';
import ENDPOINTS from '../../constants/endpoints';
import { checkResponseStatus, getUserIdToken } from '../../helpers';
import { API, apiURLBase } from '../../middlewares';
import { updateGlobalLoader } from '../../redux/globalActions';
import ACTION_NAMES from '../../constants/actionNames';
import moment from 'moment';
import routes from '../../constants/routes';
import ACCOUNT_INFO from '../../constants/info';

/**
 * @definition function to convert date format to string
 * @param _date the date entered
 * @param _format the format required
 * @param _delimiter the character to differentiate (-, /)
 */
function stringToDate(_date: any, _format: any, _delimiter: any) {
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf('mm');
  var dayIndex = formatItems.indexOf('dd');
  var yearIndex = formatItems.indexOf('yyyy');
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}

/**
 * @definition function to get the message reason code based on message type
 * @param messageType message type of the case
 * @param retrievalCode retrieval reason code
 * @param firstChargebackCode first chargeback reason code
 * @param finalChargebackCode final chargeback reason code
 */
const getDocs = (
  messageType: number,
  retrievalCode: any,
  firstChargebackCode: any,
  finalChargebackCode: any
) => {
  if (messageType === 1 && retrievalCode !== undefined && retrievalCode.length > 0) {
    return retrievalCode;
  } else if (
    messageType === 2 &&
    firstChargebackCode !== undefined &&
    firstChargebackCode.length > 0
  ) {
    return firstChargebackCode;
  } else if (
    messageType === 3 &&
    finalChargebackCode !== undefined &&
    finalChargebackCode.length > 0
  ) {
    return finalChargebackCode;
  }
};

/**
 * @definition function to get the amount based on message type
 * @param messageType message type of the case
 * @param firstCbAmt first Chargeback amount
 * @param finalCbAmt final Chargeback amount
 */
const getAmount = (messageType: number, firstCbAmt: any, finalCbAmt: any) => {
  if (messageType === 2) {
    return firstCbAmt;
  } else if (messageType === 3) {
    return finalCbAmt;
  }
};

/**
 * @definition service to get the listing of all the cases
 */
export const getCaseInboxListing = () => (dispatch: any, getState: any) => {
  dispatch(updateGlobalLoader(true));

  const {
    listType,
    caseListPage,
    caseListSearch,
    caseListLimit,
    startDate,
    ApiEndDate,
    currentSortKey,
    isCurrentSortAscending,
    messageType,
    SENumber,
    SEName,
  } = getState().CaseInboxReducer;
  const dataToSend = {
    params: {
      page: caseListPage,
      limit: caseListLimit,
      listType: listType,
      ...(caseListSearch.length && { search: caseListSearch }),
      ...(SENumber.length && { SENumber: SENumber }),
      ...(SEName.length && { SEName: SEName }),
      ...(messageType !== null && messageType !== 0 && { messageType: messageType }),
      ...(startDate !== null && { fromDate: startDate }),
      ...(ApiEndDate !== null && { toDate: ApiEndDate }),
      sortedBy: currentSortKey,
      sortOrder: isCurrentSortAscending ? 'ASC' : 'DESC',
    },
  };

  API.get(ENDPOINTS.caseInboxListing, dataToSend)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;

        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            caseListingData: data?.data?.data,
            caseListCount: data?.data?.count,
            caseListPage: data?.data?.page,
            caseListPageCount: data?.data?.pageCount,
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error: any) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * @definition service to delete a case
 * @param caseId gives the required case id
 * @param handleClose function to close the delete modal
 * @param navigate to navigate the screen after successfully deleting the case
 */
export const deleteCaseInbox =
  (caseId: string, handleClose: any, navigate: any) => (dispatch: any) => {
    dispatch(updateGlobalLoader(true));

    const params = `/${caseId}`;

    API.delete(`${ENDPOINTS.createCase}${params}`)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          handleClose();
          navigate('/case-inbox/sent');
        }
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        showAlert(2, response?.data?.message);
        dispatch(updateGlobalLoader(false));
      });
  };

/**
 * @definition service to get the transaction listing of a particular case
 * @param caseId gives the required case id
 */
export const getTransactionListing = (caseId: string) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  API.get(`${ENDPOINTS.caseTransactionListing}?caseId=${caseId}`)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;

        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            caseTransactionListing: data.data,
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * @definition service to get the case details
 * @param caseId gives the required case id
 */
export const getCaseDetails = (caseId: any) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  API.get(`${ENDPOINTS.caseDetails}?id=${caseId}`)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;
        const { caseDetails } = data.data;
        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            case_id: caseDetails?.id,
            case_caseId: caseDetails?.caseId,
            case_startingCardNumber: caseDetails?.startingCardMemberNumber,
            case_endingCardNumber: caseDetails?.endingCardMemberNumber,
            case_SENumber: caseDetails?.SENumber,
            case_SEName: caseDetails?.SEName,
            case_merchantCategoryCode: caseDetails?.merchantCategoryCode,
            case_ARNNumber: caseDetails?.ARNNumber,
            case_referenceNumber: caseDetails?.referenceNumber,
            case_terminalNumber: caseDetails?.terminalNumber,
            case_transactionDate: caseDetails?.transactionDate,
            case_transactionTime: caseDetails?.transactionTime,
            case_transactionAmount: caseDetails?.transactionAmount,
            case_transactionCurrency: caseDetails?.transactionCurrency,
            case_presentmentAmount: caseDetails?.presentmentAmount,
            case_transactionId: caseDetails?.transactionId,
            case_transactionType: caseDetails?.transactionType,
            case_approvalCode: caseDetails?.approvalCode,
            case_caseType: caseDetails?.caseType,
            case_sent: caseDetails?.sent,
            case_messageType: caseDetails?.messageType,
            case_retailerId: caseDetails?.retailerId,
            isActionTaken: caseDetails?.isActionTaken,
          },
        });
        //  for custom inputs used for edit states
        dispatch({
          type: ACTION_NAMES.CREATE_CASE,
          payload: {
            seNumberData: {
              value: caseDetails?.SENumber,
              errorText: '',
              isExists: true,
            },
            arnNumberData: {
              value: caseDetails?.ARNNumber,
              errorText: '',
              isExists: true,
            },
            referenceNumberData: {
              value: caseDetails?.referenceNumber,
              errorText: '',
              isExists: true,
            },
            transDate: stringToDate(caseDetails?.transactionDate, 'YYYY-MM-DD', '-'),
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * @definition service for updating any case based on the action performed
 * @param messageType message type of the case
 * @param handleModalClose function to close the modal
 * @param navigate to navigate the screen after successfully getting the response
 * @param values for getting updated values of the form
 * @param setSubmitting for disabling button until api gives a response
 */
export const caseAction =
  (messageType: number, handleModalClose: any, navigate: any, values?: any, setSubmitting?: any) =>
  (dispatch: any, getState: any) => {
    const { case_id } = getState().CaseInboxReducer;

    const { retrievalFiles, firstCbFiles, finalCbFiles, filePath } = getState().CreateCaseReducer;

    const retrievalMSG = values?.retrievalReason?.substring(0, 4);
    const chargeBackFirst = values?.cbReason?.substring(0, 4);
    const chargeBackFinal = values?.finalCb?.substring(0, 4);
    const itemizedDoc = values?.docType?.substring(0, 2);

    let dataToSend = {};

    dataToSend = {
      messageType,
      caseId: case_id,
      transactionCurrency: 'SAR',
      networkProcessingDate: moment().format('YYYY-MM-DD'),
      messageReasonCode: getDocs(messageType, retrievalMSG, chargeBackFirst, chargeBackFinal),
      ...(values?.comment !== undefined &&
        values?.comment !== null &&
        values?.comment?.length > 0 && { description: values?.comment }),
      ...(((values?.cbAmt !== undefined && values?.cbAmt !== null && values.cbAmt !== '') ||
        (values?.finalCbAmt !== undefined &&
          values?.finalCbAmt !== null &&
          values.finalCbAmt !== '')) && {
        amount: getAmount(messageType, values?.cbAmt, values?.finalCbAmt),
      }),
      ...(((retrievalFiles !== undefined && retrievalFiles !== null && retrievalFiles.length > 0) ||
        (firstCbFiles !== undefined && firstCbFiles !== null && firstCbFiles.length > 0) ||
        (finalCbFiles !== undefined && finalCbFiles !== null && finalCbFiles.length > 0)) && {
        documents: filePath,
      }),
      ...(itemizedDoc !== undefined &&
        itemizedDoc !== null &&
        itemizedDoc.length > 0 && { itemizedDocumentType: itemizedDoc }),
      ...(filePath !== undefined &&
        filePath !== null &&
        filePath.length > 0 && {
          totalDocuments: filePath.length,
        }),
    };

    if (messageType === 9) {
      dataToSend = {
        messageType,
        caseId: case_id,
        networkProcessingDate: moment().format('YYYY-MM-DD'),
      };
    }

    dispatch({
      type: ACTION_NAMES.CASE_INBOX,
      payload: {
        isActionButton: false,
      },
    });

    API.post(ENDPOINTS.caseAction, dataToSend)
      .then((response: any) => {
        dispatch(updateGlobalLoader(true));
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          if (
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.ACCEPT_FULFILLMENT ||
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.ACCEPT_SECOND_PRESENTMENT ||
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.CLOSE
          ) {
            navigate(routes.CASE_INBOX_CLOSED);
          } else if (
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.FULFILLMENT ||
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.SECOND_PRESENTMENT ||
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.ACCEPT_RETRIEVAL_REQUEST ||
            messageType === ACCOUNT_INFO.MESSAGE_TYPE.ACCEPT_FIRST_CHARGE_BACK
          ) {
            navigate(routes.CASE_INBOX_RECEIVED);
          } else {
            navigate(routes.CASE_INBOX_SENT);
          }
        } else {
          showAlert(2, response?.data?.message);
        }
        setSubmitting(false);
        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            isActionButton: true,
          },
        });
        dispatch(updateGlobalLoader(false));
        handleModalClose();
      })
      .catch((error) => {
        const { response } = error;
        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            isActionButton: true,
          },
        });
        showAlert(2, response?.data?.message);
        handleModalClose();
        dispatch(updateGlobalLoader(false));
      });
  };

/**
 * @definition service to download the case list in excel sheet
 * @param listType denotes whether it is under Sent/Received/Closed tab
 * @param messageType message type of the case
 * @param toDate end date
 * @param fromDate start date
 */
export const handleDownloadCaseList = async (
  listType: number,
  messageType: number,
  toDate: string,
  fromDate: string
) => {
  const date = moment().format('YYYY-MM-DD');
  const req = await API({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getUserIdToken()}`,
    },
    method: 'get',
    url: `${apiURLBase}${ENDPOINTS.caseDownload}?listType=${listType}&type=1${
      messageType && messageType !== 0 ? `&messageType=${messageType}` : ''
    }${toDate ? `&toDate=${toDate}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}&count=2`,

    responseType: 'blob',
  });
  const blob = new Blob([req.data], {
    type: req.headers['content-type'],
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `caseList${date}.xlsx`;

  link.click();
};

/**
 * @definition service to download the case list in excel sheet
 * @param listType denotes whether it is under Sent/Received/Closed tab
 * @param messageType message type of the case
 * @param toDate end date
 * @param fromDate start date
 * @param count used to get the total count(size) of the download files
 * @param setState state to manage whether to show the limit error or not
 * @param handleExportModalClose function to close the modal
 */
export const handleDownloadEmailCaseList =
  (
    listType: number,
    messageType: number,
    toDate: string,
    fromDate: string,
    count: number,
    setState?: any,
    handleExportModalClose?: any
  ) =>
  (dispatch: any) => {
    const dataToSend = {
      params: {
        listType,
        type: '2',
        toDate,
        fromDate,
        count,
        ...(messageType && messageType !== 0 && { messageType }),
      },
    };
    if (count === 1) {
      dispatch(updateGlobalLoader(true));
      API.get(ENDPOINTS.caseDownload, dataToSend)
        .then((response: any) => {
          if (checkResponseStatus(response)) {
            const { data } = response;
            if (data.data >= ACCOUNT_INFO.MAX_COUNT_LIMIT) {
              dispatch(updateGlobalLoader(false));
              setState(true);
            } else {
              dispatch(updateGlobalLoader(false));
              handleDownloadCaseList(listType, messageType, toDate, fromDate);
              handleExportModalClose();
            }
          }
        })
        .catch((error) => {
          const { response } = error;
          showAlert(2, response?.data?.message);
        });
    } else {
      API.get(ENDPOINTS.caseDownload, dataToSend)
        .then((response: any) => {
          if (checkResponseStatus(response)) {
            const { data } = response;
            showAlert(1, data?.message);
          }
        })
        .catch((error) => {
          const { response } = error;
          showAlert(2, response?.data?.message);
        });
    }
  };

/**
 * @definition function to download the uploaded images from the transaction listing content
 * @param url url of the file
 * @param filename name to show while downloading
 */
export function downloadAsset(url: string, filename: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}

/**
 * @definiton service to get the core case details
 * @param caseId id of the required case
 */
export const getCoreCaseDetails = (caseId: string) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  API.get(`${ENDPOINTS.coreCaseDetails}/${caseId}?id=${caseId}`)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;
        const caseDetails = data.data;
        dispatch({
          type: ACTION_NAMES.CASE_INBOX,
          payload: {
            case_id: caseDetails?.id,
            case_caseId: caseDetails?.caseId,
            case_startingCardNumber: caseDetails?.startingCardMemberNumber,
            case_endingCardNumber: caseDetails?.endingCardMemberNumber,
            case_SENumber: caseDetails?.SENumber,
            case_SEName: caseDetails?.SEName,
            case_merchantCategoryCode: caseDetails?.merchantCategoryCode,
            case_ARNNumber: caseDetails?.ARNNumber,
            case_referenceNumber: caseDetails?.referenceNumber,
            case_terminalNumber: caseDetails?.terminalNumber,
            case_transactionDate: caseDetails?.transactionDate,
            case_transactionTime: caseDetails?.transactionTime,
            case_transactionAmount: caseDetails?.transactionAmount,
            case_transactionCurrency: caseDetails?.transactionCurrency,
            case_presentmentAmount: caseDetails?.presentmentAmount,
            case_transactionId: caseDetails?.transactionId,
            case_transactionType: caseDetails?.transactionType,
            case_approvalCode: caseDetails?.approvalCode,
            case_caseType: caseDetails?.caseType,
            case_sent: caseDetails?.sent,
            case_messageType: caseDetails?.messageType,
            case_messageReasonCode: caseDetails?.messageReasonCode,
            case_itemizedDocumentType: caseDetails?.itemizedDocumentType,
            case_coreCase_amount: caseDetails?.amount,
            case_retailerId: caseDetails?.retailerId,
            isActionTaken: caseDetails?.isActionTaken,
          },
        });
        dispatch({
          type: ACTION_NAMES.CREATE_CASE,
          payload: {
            transDate: stringToDate(caseDetails?.transactionDate, 'YYYY-MM-DD', '-'),
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};
