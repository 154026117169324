import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const styles = makeStyles(() =>
  createStyles({
    spinner: {
      width: '100%',
      height: '350px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default function Spinner() {
  const classes = styles();

  return (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  );
}
