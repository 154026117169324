import ActionNames from '../../constants/actionNames';

export class LoginState {
  email: string = '';
  token: string = '';
}

export class LocalMachineData {
  ip: string = '';
  countryName: string = '';
  countryCode: string = '';
  accountType: number = 0;
  menuSelectedIndex: number = 0;
}

export const LoginReducer = (state: LoginState = new LoginState(), action: any = {}) => {
  if (action.type === ActionNames.SIGN_IN) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const LocalMachineReducer = (
  state: LocalMachineData = new LocalMachineData(),
  action: any = {}
) => {
  if (action.type === ActionNames.LOCAL_DATA_FETCH) {
    return { ...state, ...action.payload };
  }
  return state;
};

export default LocalMachineReducer;
