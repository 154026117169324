const apiResponses = {
  unauthorized: 401,
  accessDenied: 430,
  sessionExpired: 419,
  validationError: 400,
  userDeleteOrBlocked: 403,
  postSuccess: 201,
  success: 200,
  preconditionFailed: 412,
  conflict: 409,
};

export default apiResponses;
