const ENDPOINTS = {
  login: 'aesa/login',
  forgetPassword: 'aesa/forgetPassword',
  verifyToken: 'aesa/verify/',
  resetPassword: 'aesa/resetPassword/',
  verifyEmail: 'aesa/verifyEmail/',
  updateProfile: 'aesa/profilePicture',
  createPassword: 'aesa/createPassword/',
  addUser: 'aesa/user',
  logout: 'aesa/logout',
  recoveryAccount: 'aesa/recoveryAccount',
  downloadUserList: 'aesa/downloadUserList',
  profile: 'aesa/profile',
  listing: 'aesa/listing',
  auditListing: 'aesa/audit/listing',
  profilePicture: 'aesa/profilePicture',
  blockUnblockUser: 'aesa/blockUnblockUser',
  changePassword: 'aesa/changePassword',
  createCase: 'aesa/case',
  createCaseDocUpload: 'aesa/case/upload',
  createCaseBankSE: 'aesa/case/bankExists',
  tpBankUserListing: 'tpbank/user/listing',
  tpBankUser: 'tpbank/user/',
  tpBankUserprofile: 'tpbank/user/profile',
  tpBankauditListing: 'tpbank/audit/listing',
  blockUnblockTpbankUser: 'tpbank/blocking/user',
  tpBankRecoveryAccount: 'tpbank/recoveryAccount',
  tbBank: 'tpbank/listing',
  tpBankAdmin: 'tpbank/admin',
  tpBankReviewAdmin: 'tpbank/review/admin',
  downloadTPBankList: 'tpbank/downloadTPBankList',
  tbBankDetails: 'tpbank',
  tpBankReview: 'tpbank/review',
  blockUnblockTpbank: 'tpbank/blocking',
  getIPAddress: 'https://ipapi.co/json/',
  caseInboxListing: 'aesa/case/inbox/listing',
  caseDetails: 'aesa/case/caseDetails',
  caseDownload: 'aesa/case/download',
  caseAction: 'aesa/case/action',
  caseTransactionListing: 'aesa/case/transaction/listing',
  createCaseValidationMsg: 'aesa/case/validationMsg',
  createCaseARNExists: 'aesa/case/arnExists',
  createCaseRefExists: 'aesa/case/refExists',
  createCaseEdit: 'aesa/case',
  auditLogListing: 'aesa/audit/log',
  dashboard: 'aesa/dashboard',
  notification: 'notification',
  downloadDashboard: 'aesa/downloadDashboard',
  notificationCount: 'notification/count',
  userNotification: 'notification/my',
  deleteNotificationAll: 'notification/all',
  notificationList: 'notification/listing',
  downloadNotification: 'notification/download',
  deleteNotification: 'notification/',
  templateNotificationList: 'notification/listing/template',
  templateNotificationLogs: 'notification/template/logs',
  editTemplateNotification: 'notification/template',
  coreCaseListing: 'coreCase/listing',
  codeCaseValidate: 'coreCase/validate',
  codeCaseSearchValidate: 'coreCase/validate/searchCase',
  coreCaseDetails: 'coreCase/details',
  codeCaseSearch: 'coreCase/search',
  searchCaseCheck: 'coreCase/search-case-check',
  notificationResend: 'notification/resend',
  searchTransactionDetails: 'coreCase/getTransactionDetails',
  searchAuthTransactionDetails: 'coreCase/getAuthTransactionDetails',
  archivedTpbankList: 'tpbank/archivedListing',
  retrieveTpBank: 'tpbank/retrieve',
};

export default ENDPOINTS;
