import { COLORS } from '../../constants/colors';

const styles = {
  mainContainer: {
    height: '100vh',
    padding: '1.694vw 0 0.694vw 0',
    width: '76vw',
    backgroundColor: COLORS.bgColor,
  },
  linkBtn: {
    margin: '0 0 0.694vw 0',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  btnContainer: {
    alignItems: 'flex-end',
  },
  heading: {
    font: 'normal normal 700 1.250vw/1.250vw BentonSans',
    color: COLORS.headingBlue,
    margin: '5px 0 0 0',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 0 0 0',
  },
  searchBy: {
    flexBasis: '27%',
    backgroundColor: COLORS.white,
    border: '2px solid rgba(209, 209, 209, 0.7)',
    padding: '2px 17px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '4px',
    '& hr': {
      height: '40px',
    },
  },
  searchBox: {
    flexBasis: '65%',
    backgroundColor: COLORS.white,
    border: '2px solid rgba(209, 209, 209, 0.7)',
    borderRadius: '4px',
    display: 'flex',
    '& .MuiOutlinedInput-input': {
      font: 'normal normal 500 1.250vw/1.250vw BentonSans',
      lineHeight: '40px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  search: {
    font: 'normal normal 500 1.250vw/1.250vw BentonSans',
  },
  selectFilter: {
    '& .MuiInputBase-root:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 'none !important',
    },
  },
  btn: {
    width: '16.500vw',
    font: 'normal normal 550 0.972vw/1.250vw BentonSans',
    margin: '2px 0',
  },
  mainTableContainer: {
    marginTop: '15px',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  filtersContainer: {
    fontFamily: 'BentonSans',
    fontStyle: 'normal',
    fontWeight: 550,
    fontSize: '18px',
    lineHeight: '18px',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1%',
  },
};

export default styles;
