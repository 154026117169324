import dayjs, { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class CreateCaseState {
  selectedIds: any = [];
  listingData = [];
  userListPageCount: number = 1;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListCount: number = 0;
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  isFilter: boolean = false;
  lifecycleData = [];
  retrievalFiles: any = [];
  firstCbFiles: any = [];
  finalCbFiles: any = [];
  coreCaseFiles: any = [];
  tabState: number = 0;
  values: any = {};
  isSeExists: boolean = false;
  retrievalMsg = [];
  chargeBackReason = [];
  itemizedDocMsg = [];
  isARNExists: boolean = false;
  filePath: any = [];
  transDate: Dayjs | null = null;
  bankDocumentId: string = '';
  transTime: Dayjs | string = dayjs(new Date()).format('hh:mm:ss A');
  seNumberData = {
    value: '',
    errorText: '',
    isExists: false,
  };
  arnNumberData = {
    value: '',
    errorText: '',
    isExists: false,
  };
  referenceNumberData = {
    value: '',
    errorText: '',
    isExists: true,
  };
  transIdData = {
    value: '',
    errorText: '',
    isExists: true,
  };

  seName: string = '';
  seNumber: string = '';
  messageType = 0;
}

export const CreateCaseReducer = (
  state: CreateCaseState = new CreateCaseState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.CREATE_CASE) {
    return { ...state, ...action.payload };
  }
  return state;
};
