import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import colorConstant from '../../constants/colorConstant';
import warningStyle from './warningModalStyles';
import { useSelector } from 'react-redux';
import { ReducersState } from '../../redux/rootReducer';

const styles = {
  mainHeaderContainer: {
    height: '5.972vw',
    padding: '0 2.917vw',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colorConstant.amexWhite,
    flexGrow: 1,
    alignItems: 'center',
    boxShadow: '0px 3px 17px rgba(0, 0, 0, 0.1);',
  },
  logoHeadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: '17.361vw',
  },
  headerLogo: {
    width: '9.028vw',
  },

  headerTextStyle: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '1.528vw',
    lineHeight: '1.250vw',
    marginLeft: '8.611vw',
  },

  notificationLogo: {
    width: '3.333vw',
  },
  profileText: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '0.972vw',
    lineHeight: '1.250vw',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  userMenuFont: {
    fontFamily: 'BentonSans',
    fontWeight: 550,
    fontSize: '0.972vw',
    lineHeight: '1.250vw',
    padding: '0.694vw',
  },
  logoutContaner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '1.389vw 1.667vw',
    width: '27.014vw',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colorConstant.amexWhite,
    borderRadius: '0.556vw',
    '& svg': {
      fontSize: '5.556vw',
      color: colorConstant.alertRed,
    },
  },
  logoutHeading: {
    fontFamily: 'BentonSans',
    fontWeight: 700,
    fontSize: '1.528vw',
    lineHeight: '1.667vw',
    margin: '2.222vw 0 0.694vw 0',
  },
  logoutText: {
    fontFamily: 'BentonSans',
    fontWeight: 550,
    fontSize: '1.042vw',
    textAlign: 'center',
    lineHeight: '1.806vw',
    color: '#3A5263',
    opacity: 0.8,
    marginBottom: '28px',
  },
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    '& button': {
      minWidth: '10.278vw',
    },
  },
};

export default function ConfirmationPopup({
  warningMessage,
  actionText,
  closeText,
  handleClose,
  onConfirm,
}: any) {
  const classes = warningStyle();

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);
  const { isLoading } = useSelector((state: ReducersState) => state.GlobalReducer);

  return (
    <div style={modalStyle} className={classes.paper}>
      <Box sx={styles.logoutContaner}>
        <ErrorRoundedIcon />
        <Typography sx={styles.logoutHeading}>Are you sure?</Typography>
        <Typography sx={styles.logoutText}> {warningMessage}</Typography>
        <Box sx={styles.logoutButtonContainer}>
          <Button
            onClick={() => {
              handleClose();
            }}
            size="large"
            variant="outlined"
          >
            {closeText}
          </Button>
          <Button
            onClick={() => {
              onConfirm();
            }}
            disabled={isLoading}
            size="large"
            variant="contained"
            color="primary"
          >
            {actionText}
          </Button>
        </Box>
      </Box>
    </div>
  );
}
