import { makeStyles, createStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'space-between !important',
      padding: '0px 10px !important',
    },
    leftContainer: {
      display: 'flex !important',
      alignItems: 'center !important',

      '& .MuiSvgIcon-root': {
        color: 'white !important',
        display: 'none',
      },
    },
    rightContainer: {
      display: 'flex !important',
      alignItems: 'center !important',
      '& .MuiSvgIcon-root': {
        color: 'white !important',
      },
    },
    message: {
      marginLeft: '5px !important',
      textAlign: 'left',
      fontFamily: 'BentonSans',
      fontStyle: 'normal',
      fontWeight: 550,
      fontSize: '0.8em',
      lineHeight: '18px',
    },
    remainingTime: {
      marginRight: '5px !important',
      color: 'white !important',
    },
  })
);
