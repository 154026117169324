import { COLORS } from '../../../constants/colors';

const styles = {
  btn: {
    textTransform: 'capitalize',
    cursor: 'pointer',
    height: '4.167vw',
    borderRadius: '0.278vw',
    boxShadow: `0 0.069vw 1.181vw ${COLORS.shadow} !important`,
    '& MuiButton-root .Mui-disabled': {
      boxShadow: `0 0.069vw 1.181vw ${COLORS.shadow} !important`,
    },
  },
  btn1: {
    boxShadow: `none !important`,
    '& MuiButton-root .Mui-disabled': {
      boxShadow: `none !important`,
    },
  },
};

export default styles;
