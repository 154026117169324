import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ACCOUNT_INFO from '../../constants/info';
import { COLORS } from '../../constants/colors';
import routes from '../../constants/routes';
import useCaseInboxHelper from '../../pages/caseInbox/helper';
import useSearchCoreSystemHelper from '../../pages/searchInCoreSystem/helper';
import Helpers from '../../helpers';
import ContentCopyButton from '../contentCopyButton';

const SearchInCoreListing = ({ userListSearch, searchValue, isSearchCase = false }: any) => {
  const styles = {
    paginationContainer: {
      backgroundColor: 'rgb(245 247 253)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1.389vw 0',
    },
    categories: {
      '& th': {
        backgroundColor: 'rgb(245 247 253)',
        fontFamily: 'BentonSans',
        fontWeight: 650,
        fontSize: '0.9vw',
        lineHeight: '1.1em',
        letterSpacing: '0.01em',
        color: '#656F91',
        maxWidth: '16.66%',
      },
    },
    tableContent: {
      font: 'normal normal 550 1.042vw/1.250vw BentonSans',
      fontSize: '0.95vw',
      color: COLORS.tableText,
      whiteSpace: 'noWrap',
    },
    tableContent1: {
      color: COLORS.primary,
    },
    checkbox: {
      color: COLORS.checkBorder,
    },
    formLabel: {
      margin: '0 0 0 0.747vw !important',
    },
    withCopyButton: {
      minWidth: '14vw!important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };

  const { getMessageType, getAmount, paramPath } = useCaseInboxHelper();

  const { caseListingData, caseListPage, caseListPageCount, handleChange } =
    useSearchCoreSystemHelper();

  return (
    <Box>
      <TableContainer>
        <Table stickyHeader aria-label="customized table">
          <TableHead sx={styles.categories}>
            <TableRow>
              <TableCell>CASE ID</TableCell>
              <TableCell>TID</TableCell>
              <TableCell>TXN TYPE</TableCell>
              <TableCell>TXN AMOUNT</TableCell>
              <TableCell>ARN NO.</TableCell>
              <TableCell>MESSAGE TYPE</TableCell>
              <TableCell>DATE{!isSearchCase && `/CASE TYPE`}</TableCell>
              <TableCell>TXN TIME</TableCell>
              <TableCell>CARD MEMBER NUMBER</TableCell>
              <TableCell>SE NO.</TableCell>
              <TableCell>REFERENCE NUMBER</TableCell>
              <TableCell>RETAILER ID</TableCell>
              <TableCell>SE NAME</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caseListingData && caseListingData.length > 0 ? (
              caseListingData.map((row: any, index: any) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      ...styles.tableContent,
                      ...styles.tableContent1,
                    }}
                  >
                    <span
                      style={{
                        minWidth: '14vw!important',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Link
                        to={
                          isSearchCase
                            ? `${routes.CORE_CASE}?searchCase=${row.ARNNumber}&searchOn=arnNumber&caseType=${row.messageType}`
                            : `${routes.CASE_INBOX}?id=${row.id}`
                        }
                      >
                        {row.caseId ? row.caseId : '-'}
                      </Link>
                      <ContentCopyButton
                        // fontSize="small"
                        onClick={() => Helpers.copyToClipboard(row.caseId)}
                      />
                    </span>
                  </TableCell>

                  <TableCell sx={{ ...styles.tableContent, wordBreak: 'break-word' }}>
                    <Box>
                      {row.transactionId ? row.transactionId : '-'}
                      {row.transactionId ? (
                        <ContentCopyButton
                          onClick={() => Helpers.copyToClipboard(row.transactionId)}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </TableCell>

                  <TableCell sx={styles.tableContent}>
                    {row.transactionType === ACCOUNT_INFO.TRANSACTION_TYPE.ATM ? 'ATM' : 'POS'}
                  </TableCell>

                  <TableCell sx={styles.tableContent}>{getAmount(row.transactionAmount)}</TableCell>

                  <TableCell sx={styles.tableContent}>
                    <Box>
                      {row.ARNNumber ? row.ARNNumber : '-'}
                      <ContentCopyButton onClick={() => Helpers.copyToClipboard(row.ARNNumber)} />
                    </Box>
                  </TableCell>

                  <TableCell sx={styles.tableContent}>
                    {row.messageType ? getMessageType(row.messageType) : '-'}
                  </TableCell>
                  <TableCell sx={styles.tableContent}>
                    {paramPath === routes.SEARCH_IN_CORE_SYSTEM
                      ? row.transactionDate
                        ? moment(row.transactionDate).format('DD/MM/YYYY')
                        : '-'
                      : row.actionDate
                      ? moment(row.actionDate).format('DD/MM/YYYY')
                      : '-'}
                    {!isSearchCase && (
                      <Typography sx={{ ...styles.tableContent, ...styles.tableContent1 }}>
                        {row.caseType === ACCOUNT_INFO.CASE_TYPE.API ? 'API' : 'Manual'}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={styles.tableContent}>
                    {row.transactionTime ? row.transactionTime : '-'}
                  </TableCell>

                  <TableCell sx={styles.tableContent}>
                    {row.startingCardMemberNumber && row.endingCardMemberNumber
                      ? `${row.startingCardMemberNumber}xxxxxxx${row.endingCardMemberNumber}`
                      : '-'}
                  </TableCell>
                  <TableCell sx={styles.tableContent}>
                    {row.SENumber ? row.SENumber : '-'}
                  </TableCell>
                  <TableCell
                    sx={{ ...styles.tableContent, ...styles.tableContent1, color: '#343B44' }}
                  >
                    <Tooltip title={row?.referenceNumber}>
                      <span>
                        {row.referenceNumber
                          ? row.referenceNumber.length > 25
                            ? `${row.referenceNumber.slice(0, 25)}...`
                            : row.referenceNumber
                          : '-'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{ ...styles.tableContent, ...styles.tableContent1, color: '#343B44' }}
                  >
                    <Box>
                      <Tooltip title={row?.retailerId}>
                        <span>
                          {row.retailerId
                            ? row.retailerId.length > 25
                              ? `${row.retailerId.slice(0, 25)}...`
                              : row.retailerId
                            : '-'}
                        </span>
                      </Tooltip>
                      {row.retailerId ? (
                        <ContentCopyButton
                          onClick={() => Helpers.copyToClipboard(row.retailerId)}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </TableCell>

                  <TableCell sx={styles.tableContent}>{row.SEName ? row.SEName : '-'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <div>No Records</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={styles.paginationContainer}>
        <Stack spacing={2}>
          <Pagination page={caseListPage} onChange={handleChange} count={caseListPageCount} />
        </Stack>
      </Box>
    </Box>
  );
};

export default SearchInCoreListing;
