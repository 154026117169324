import { useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ACCOUNT_INFO from '../../constants/info';
import ACTION_NAMES from '../../constants/actionNames';
import routes from '../../constants/routes';
import { ReducersState } from '../../redux/rootReducer';
import { getApiCaseListing } from './service';

const useCreateCaseHelper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const paramPath = location.pathname;
  const paramId = location.search.split('=');

  const [openCoreCaseModal, setOpenCoreCaseModal] = useState(false);

  const {
    userListPage,
    userListPageCount,
    userListSearch,
    userListCount,
    isFilter,
    startDate,
    endDate,
    lifecycleData,
    retrievalFiles,
    firstCbFiles,
    finalCbFiles,
    tabState,
    values,
    isSeExists,
    retrievalMsg,
    chargeBackReason,
    itemizedDocMsg,
    isARNExists,
    seNumberData,
    arnNumberData,
    transDate,
    filePath,
    transTime,
    selectedIds,
    listingData,
    messageType,
    seNumber,
    seName,
    referenceNumberData,
    transIdData,
  } = useSelector((state: ReducersState) => state.CreateCaseReducer);

  const [isApplyEnable, setIsApplyEnable] = useState(false);

  const { isLoading } = useSelector((state: ReducersState) => state.GlobalReducer);

  const {
    case_caseId,
    case_startingCardNumber,
    case_endingCardNumber,
    // case_SENumber,
    case_SEName,
    case_merchantCategoryCode,
    case_ARNNumber,
    case_referenceNumber,
    case_terminalNumber,
    case_transactionDate,
    case_transactionTime,
    case_transactionAmount,
    // case_transactionCurrency,
    case_presentmentAmount,
    case_transactionId,
    case_transactionType,
    case_approvalCode,
    case_retailerId,
  } = useSelector((state: ReducersState) => state.CaseInboxReducer);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch({
      type: ACTION_NAMES.CREATE_CASE,
      payload: {
        userListPage: value,
      },
    });
    dispatch(getApiCaseListing());
  };

  const roleCheck = (status: number) => {
    switch (status) {
      case ACCOUNT_INFO.ROLES.superAdmin:
        return 'Super Admin ';
      case ACCOUNT_INFO.ROLES.manager:
        return 'Manager ';
      default:
        return 'Operator ';
    }
  };

  const handleAddUser = (type: number) => {
    if (type === ACCOUNT_INFO.ROLES.manager) {
      navigate(routes.CREATE_CASE_MANAGER);
    } else navigate(routes.CREATE_CASE_OPERATOR);
    dispatch({
      type: ACTION_NAMES.CREATE_CASE,
      payload: {
        transDate: '',
      },
    });
  };

  // for searching data and fetching accordingly
  const handleSearch = (event: any) => {
    dispatch({
      type: ACTION_NAMES.CREATE_CASE,
      payload: {
        userListSearch: event.target.value,
        userListPage: 1,
      },
    });
  };

  const handleCancel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== null) {
      dispatch({
        type: ACTION_NAMES.CREATE_CASE,
        payload: {
          transDate: '',
          retrievalFiles: [],
          firstCbFiles: [],
          finalCbFiles: [],
          tabState: 0,
          retrievalMsg: [],
          isSeExists: false,
          itemizedDocMsg: [],
          isARNExists: false,
          filePath: [],
        },
      });
    }

    navigate(-1);
  };

  const handleFilter = () => {
    setIsApplyEnable(true);
    dispatch({
      type: ACTION_NAMES.CREATE_CASE,
      payload: {
        userListPage: 1,
      },
    });
    dispatch(getApiCaseListing());
  };

  const handleFilterReset = () => {
    setIsApplyEnable(false);
    dispatch({
      type: ACTION_NAMES.CREATE_CASE,
      payload: {
        seName: '',
        seNumber: '',
        startDate: null,
        ApiEndDate: null,
        endDate: null,
        messageType: 0,
      },
    });
    dispatch(getApiCaseListing());
  };

  const addCaseInitials = {
    cardNumber:
      paramPath === routes.CASE_EDIT_MANUAL
        ? `${case_startingCardNumber}xxxxxxx${case_endingCardNumber}`
        : '',
    transDate: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionDate : '',
    // seNum: paramPath === routes.CASE_EDIT_MANUAL ? case_SENumber : '',
    transTime: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionTime : '  :  :  :  ',
    seName: paramPath === routes.CASE_EDIT_MANUAL ? case_SEName : '',
    transAmount: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionAmount : '',
    merchantCode: paramPath === routes.CASE_EDIT_MANUAL ? case_merchantCategoryCode : '',
    presentmentAmount: paramPath === routes.CASE_EDIT_MANUAL ? case_presentmentAmount : '',
    // arnNo: paramPath === routes.CASE_EDIT_MANUAL ? case_ARNNumber : '',
    aprCode: paramPath === routes.CASE_EDIT_MANUAL ? case_approvalCode : '',
    terminalNo: paramPath === routes.CASE_EDIT_MANUAL ? case_terminalNumber : '',
    transId: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionId : '',
    caseId: paramPath === routes.CASE_EDIT_MANUAL ? case_caseId : '',
    transType: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionType : '',
    retailerId: paramPath === routes.CASE_EDIT_MANUAL ? case_retailerId : '',
    retrievalReason: '',
    docType: '',
    comment: '',
    cbReason: '',
    cbAmt: '',
    finalCb: '',
    // finalCbAmt: '',
    finalCbAmt: paramPath === routes.CASE_EDIT_MANUAL ? case_transactionAmount : '',
    tabValue: tabState,
    isEdit: paramPath === routes.CASE_EDIT_MANUAL ? true : false,
  };

  const isButtonDisable = () => {
    if (startDate !== null && endDate !== null) {
      return dayjs(endDate) < dayjs(startDate);
    }

    if (
      startDate !== null ||
      endDate !== null ||
      messageType !== 0 ||
      seNumber !== '' ||
      seName !== '' ||
      isApplyEnable
    ) {
      return false;
    }
    return true;
  };

  return {
    dispatch,
    navigate,
    listingData,
    userListPage,
    userListPageCount,
    handleChange,
    roleCheck,
    handleAddUser,
    userListSearch,
    userListCount,
    isFilter,
    startDate,
    endDate,
    handleSearch,
    handleFilterReset,
    handleFilter,
    paramId,
    paramPath,
    lifecycleData,
    addCaseInitials,
    retrievalFiles,
    firstCbFiles,
    finalCbFiles,
    tabState,
    values,
    isSeExists,
    retrievalMsg,
    chargeBackReason,
    itemizedDocMsg,
    isARNExists,
    seNumberData,
    arnNumberData,
    transIdData,
    transDate,
    handleCancel,
    filePath,
    transTime,
    case_ARNNumber,
    selectedIds,
    openCoreCaseModal,
    setOpenCoreCaseModal,
    messageType,
    seNumber,
    seName,
    isButtonDisable,
    isLoading,
    referenceNumberData,
    case_referenceNumber,
    isApplyEnable,
    setIsApplyEnable,
    case_retailerId,
    case_transactionId,
  };
};

export default useCreateCaseHelper;
