import ACTION_NAMES from '../../constants/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersState } from '../../redux/rootReducer';
import { getCaseInboxListing } from '../caseInbox/services';

const useSearchCoreSystemHelper = () => {
  const dispatch = useDispatch();

  const { caseListingData, caseListPage, caseListPageCount, caseListCount, caseListSearch } =
    useSelector((state: ReducersState) => state.SearchInCoreReducer);

  // for searching data and fetching accordingly
  const handleSearch = (event: any) => {
    dispatch({
      type: ACTION_NAMES.SEARCH_IN_CORE,
      payload: {
        caseListSearch: event.target.value,
        caseListPage: 1,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch({
      type: ACTION_NAMES.SEARCH_IN_CORE,
      payload: {
        caseListPage: value,
      },
    });
    dispatch(getCaseInboxListing());
  };

  return {
    handleSearch,
    caseListingData,
    caseListPage,
    caseListPageCount,
    caseListCount,
    caseListSearch,
    handleChange,
  };
};

export default useSearchCoreSystemHelper;
