import { COLORS } from '../../../constants/colors';

const styles = {
  btn: {
    textTransform: 'capitalize',
    cursor: 'pointer',
    color: COLORS.primary,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
};

export default styles;
