/* eslint-disable */
/**
 * This is for helpers functions
 * to support the component interactions
 */
import moment from 'moment';
import ACCOUNT_INFO from '../constants/info';
import apiErrorCodes from '../constants/apiResponseCodes';
import { getAccessToken } from '../constants/session';
import { API } from '../middlewares';
import { logoutApi } from '../pages/login/services';

// get local storage value
export const getStorageItem = (key: string) => localStorage.getItem(key);

// set local storage value
export const setStorageItem = (key: string, value: string) => localStorage.setItem(key, value);

// remove local storage value
export const removeStorageItem = (key: string) => localStorage.removeItem(key);

// Get stored user token
export const getUserIdToken = () => getStorageItem('accessToken');

/**
 * function to set authorization token
 * @param token
 */
export function setAuthorizationToken(token: string | null) {
  if (token) {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

/**
 * for 2 decimal places at max
 * @param value
 * @param digitsAfterDecimal
 * @returns digits with decimal points
 */
export function maxDecimalValue(value: number, digitsAfterDecimal: number) {
  return +value.toFixed(digitsAfterDecimal);
}

/**
 * for always 2 decimal values
 * @param value
 * @param digitsAfterDecimal
 * @returns digits with decimal points
 */
export function alwaysDecimalValue(value: number, digitsAfterDecimal: number) {
  return (Math.round(value * 100) / 100).toFixed(digitsAfterDecimal);
}

/**
 * for getting url as params
 * @param name
 * @returns param url
 */
export function getURLParams(name: string) {
  const URLParams = new URLSearchParams(window.location.search);
  return URLParams.get(name);
}

/**
 * to check status for active & inactive
 * @param active
 * @param inactive
 * @returns number for active & inactive
 */
export function checkStatus(active: boolean, inactive: boolean) {
  if (active) {
    return 1;
  }
  if (active === false && inactive === false) {
    return 1;
  }
  return 0;
}

/**
 * check user's authorization
 */
export function checkUserValidation(data: any) {
  if (data) {
    const { statusCode } = data;
    const { sessionExpired, unauthorized, accessDenied } = apiErrorCodes;
    if (statusCode) {
      return (
        statusCode === sessionExpired || statusCode === unauthorized || statusCode === accessDenied
      );
    }
    const { status } = data;
    if (status) {
      return status === sessionExpired || status === unauthorized || status === accessDenied;
    }

    return false;
  }
  return false;
}

/**
 * to get status of active | inactive
 * @param active
 * @param inactive
 * @returns number for active | inactive case
 */
export function getStatusFilterCount(active: boolean, inactive: boolean) {
  if (active && inactive) {
    return 2;
  }
  if (active && !inactive) {
    return 1;
  }
  if (!active && inactive) {
    return 1;
  }
  return 0;
}

/**
 * check api response
 * @param response
 * @returns true false
 */
export function checkResponseStatus(response: any) {
  if (response?.status === 200 || response?.status === 201 || response?.status === 202) {
    return true;
  }
  return false;
}

/**
 * add commas to numeric value
 * @param x
 * @returns numeric value along with commas or hyphen for empty string
 */
export const numberWithCommas = (x: any) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};

/**
 * to check if value exists or not
 * @param value
 * @returns value or hyphen for empty string
 */
export const checkIfValueExists = (value: any) => {
  if (value) {
    return value;
  }
  return '-';
};

/**
 * to check whether there is number or not
 * @param value
 * @returns numeric value or zero for empty cases
 */
export const checkIfNumberValueExists = (value: any) => {
  if (value) {
    return numberWithCommas(Number(value).toFixed());
  }
  return 0;
};

/**
 * check whether numeric value exists without including commas
 * @param value
 * @returns numeric value without commas or zero for empty cases
 */
export const checkIfNumberValueExistsWithoutCommas = (value: any) => {
  if (value) {
    return Number(value).toFixed();
  }
  return 0;
};

/**
 * check value of days exists or not
 * @param value
 * @returns value or zero
 */
export const checkIfDaysValueExists = (value: any) => {
  if (value) {
    if (Math.floor(Number(value)) === Math.ceil(Number(value))) {
      return Number(value);
    }
    return Number(value).toFixed(2);
  }
  return 0;
};

/**
 * check whether date exists
 * @param value
 * @returns date or hyphen
 */
export const checkIfDateValueExists = (value: any) => {
  if (value) {
    return moment(value).format('YYYY-MM-DD');
  }
  return '-';
};

/**
 * check whether date exists
 * @param date
 * @returns date or hyphen
 */
export const showDate = (date: any) => {
  if (date) return moment(date).format('YYYY-MM-DD');
  return '-';
};

/**
 * check whether date exists in month format
 * @param date
 * @returns date or hyphen
 */
export const showDateMonthFormat = (date: any) => {
  if (date) return moment(date).format('MMM D, YYYY');
  return '-';
};

/**
 * check whether input field is touched or generates error
 * @param touched
 * @param errors
 * @returns touched and error
 */
export const getHelperText = (touched: any, errors: any) => touched && errors;

/**
 * check if field contains any errors | touched or not
 * @param touched
 * @param errors
 * @returns true or false for error and touched
 */
export const isErrorOccured = (touched: any, errors: any) => Boolean(touched && errors);
export const checkIfDataPresent = (data: any) => data && data.length > 0;

/**
 * check reference value
 * @param referenceKey
 * @param reference
 * @returns referenceKey if it exists, otherwise reference
 */
export const getReferenceValue = (referenceKey: any, reference: string) => {
  if (referenceKey && referenceKey !== '') {
    return referenceKey;
  }
  return reference;
};

/**
 * to change color on full screen
 * @param fullScreenStatus
 * @returns color
 */
export const getFullScreenStatusColor = (fullScreenStatus: any) => {
  if (fullScreenStatus) {
    return '#fff';
  }
  return '#1689FA';
};

/**
 * to get date in UTC or local format
 * @param durationType
 * @param date
 * @returns date in UTC format
 */
export const getDateInUTCOrLocalByType = (durationType: number, date: Date) => {
  if (durationType === 1) {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * to add pcs after value
 * @param str
 * @returns value with pcs
 */
export const getTemplateString = (str: any) => `${checkIfNumberValueExists(str)} pcs`;

/**
 * get complete date or hyphen
 * @param row
 * @returns complete time or hyphen
 */
export const showCompletedTime = (row: any) => {
  if (row) return moment(row).format('DD/MM/YYYY');
  return '-';
};

/**
 * to check which fields are changed during editing
 * @param values
 * @param initialValues
 * @returns changed values in a form while editing
 */
export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

/**
 * to add hyphen between phone number
 * @param phoneNumber
 * @returns phone number with hyphen
 */
export const getPhoneNumberHyphenSeparated = (phoneNumber: number) => {
  const phone = `${phoneNumber}`;
  const txt = phone.slice(0, 5) + '-' + phone.slice(5);

  return txt;
};

/**
 * to check account type
 * @returns accountType or null
 */
const getAccountType = () => {
  const accountType = getStorageItem('accountType');
  if (accountType) {
    return parseInt(accountType);
  }
  return null;
};

/**
 * to check role of the user
 * @param type
 * @returns role of the user
 */
const roleCheck = (type: number) => {
  switch (type) {
    case ACCOUNT_INFO.ROLES.superAdmin:
      return 'Super Admin';
    case ACCOUNT_INFO.ROLES.tpBankSuperAdmin:
      return 'Super Admin';
    case ACCOUNT_INFO.ROLES.manager:
      return 'Manager';
    case ACCOUNT_INFO.ROLES.tpBankManager:
      return 'Manager';
    case ACCOUNT_INFO.ROLES.operator:
      return 'Operator';
    case ACCOUNT_INFO.ROLES.tpBankOperator:
      return 'Operator';
    default:
      return 'User';
  }
};

/**
 * to check the panel
 * @param type
 * @returns name of the panel
 */
const roleCheckWithPlatform = (type: number) => {
  switch (type) {
    case ACCOUNT_INFO.ROLES.superAdmin:
      return 'AESA Super Admin';
    case ACCOUNT_INFO.ROLES.tpBankSuperAdmin:
      return 'TP Bank Super Admin';
    case ACCOUNT_INFO.ROLES.manager:
      return 'AESA Manager';
    case ACCOUNT_INFO.ROLES.tpBankManager:
      return 'TP Bank Manager';
    case ACCOUNT_INFO.ROLES.operator:
      return 'AESA Operator';
    case ACCOUNT_INFO.ROLES.tpBankOperator:
      return 'TP Bank Operator';
    default:
      return 'User';
  }
};

/**
 * to get type of uploaded file
 * @param file
 * @returns file type
 */
export function getFileType(file: any) {
  const { type } = file;
  if (type.match('image/png') || type.match('image/jpeg') || type.match('image/jpg'))
    return 'image';

  if (file.type.match('video.*')) return 'video';

  if (file.type.match('audio.*')) return 'audio';

  return 'other';
}

/**
 * to get parameter through name
 * @param name
 * @param url
 * @returns parameter
 */
export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * to logout user after a certain time
 * @param dispatch
 * @param navigate
 */
export function logoutManual(dispatch: any, navigate: any) {
  setTimeout(() => {
    if (getAccessToken()) {
      dispatch(logoutApi(navigate));
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('redirectTo');
      delete API.defaults.headers.common['Authorization'];
      navigate('/');
    }
  }, 3000);
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      if (document.getElementById('custom-tooltip')) {
        document.getElementById('custom-tooltip')!.style.display = 'inline';

        setTimeout(function () {
          if (document.getElementById('custom-tooltip')) {
            document.getElementById('custom-tooltip')!.style.display = 'none';
          }
        }, 1000);
      } else {
        console.error('No element found to show copied to clipboard message');
      }
    },
    () => {
      console.error('Failed to copy');
      /* Rejected - text failed to copy to the clipboard */
    }
  );
}

const Helpers = {
  getAccountType,
  roleCheck,
  getFileType,
  getParameterByName,
  logoutManual,
  roleCheckWithPlatform,
  copyToClipboard,
};

export default Helpers;
