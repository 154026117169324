import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContainedButton from '../../components/buttons/contained-button';
import LinkButton from '../../components/buttons/link-button';
import { COLORS } from '../../constants/colors';
import LocalImages from '../../constants/localImages';
import useCreateCaseHelper from './helper';

const CreateCaseDetail = () => {
  const styles = {
    mainContainer: {
      height: '100vh',
      padding: '1.694vw 0 0.694vw 0',
      width: '76vw',
      backgroundColor: COLORS.bgColor,
    },
    linkBtn: {
      margin: '0 0 0.694vw 0',
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    heading: {
      font: 'normal normal 700 1.250vw/1.250vw BentonSans',
      color: COLORS.headingBlue,
      margin: '10px 0 0 0',
    },
    selectContainer: {
      display: 'flex',
      width: '45%',
    },
    btn: {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
      font: 'normal normal 550 1.111vw/1.250vw BentonSans',
      '&:hover': {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
      },
    },
    validate: {
      margin: '0 1.389vw 0 0',
      backgroundColor: COLORS.white,
      color: COLORS.primary,
      '&:hover': {
        backgroundColor: COLORS.white,
        color: COLORS.primary,
      },
    },
    whiteBox: {
      backgroundColor: COLORS.white,
      color: COLORS.greyText,
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '0.556vw',
      flexBasis: '45%',
      padding: '0 1.389vw 1.389vw 1.389vw',
      opacity: '0.8',
    },
    transDetail: {
      font: 'normal normal 550 1.111vw/1.250vw BentonSans',
      padding: '1.389vw 0 0 0',
    },
    transData: {
      color: COLORS.black,
    },
    transContainer: {
      margin: '1.789vw 0 0 0',
    },
    btnContainer: {
      alignItems: 'flex-end',
    },
    lifeCycle: {
      margin: '1.789vw 0 0 0',
    },
    tableContainer: {
      borderRadius: '8px',
      margin: '1.597vw 0 2.197vw 0',
      border: `1px solid ${COLORS.lightBorder}`,
      boxShadow: '0 0.278vw 0.417vw rgba(0, 0, 0, 0.02)',
    },
    tableHead: {
      backgroundColor: COLORS.bgColor,
    },
    tableHeadText: {
      font: 'normal normal 550 1.042vw/1.250vw BentonSans',
      color: COLORS.tableBlue,
    },
    bodyText: {
      font: 'normal normal 550 1.042vw/1.250vw BentonSans',
    },
    caption: {
      font: 'normal normal 550 1.042vw/1.250vw BentonSans',
      color: COLORS.greyOpacityText,
      margin: '7px 0 0 0',
    },
  };

  const { paramId, lifecycleData } = useCreateCaseHelper();
  const navigate = useNavigate();

  function LifeCycle() {
    return (
      <>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={styles.tableHead}>
              <TableRow>
                <TableCell sx={styles.tableHeadText}>VIEW DETAILS</TableCell>
                <TableCell sx={styles.tableHeadText}>NETWORK PROCESSING DATE</TableCell>
                <TableCell sx={styles.tableHeadText}>MESSAGE TYPE</TableCell>
                <TableCell sx={styles.tableHeadText}>MESSAGE REASON CODE</TableCell>
                <TableCell sx={styles.tableHeadText}>PRESENTMENT AMOUNT</TableCell>
                <TableCell sx={styles.tableHeadText}>TOTAL DOCUMENTS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lifecycleData && lifecycleData.length > 0 ? (
                lifecycleData.map((row: any, index: any) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row" sx={styles.bodyText}>
                      View
                    </TableCell>
                    <TableCell sx={styles.bodyText}>02/01/2022</TableCell>
                    <TableCell sx={styles.bodyText}>First Presentment</TableCell>
                    <TableCell sx={styles.bodyText}>
                      {/* {showCompletedTime(row.createdAt)} */}
                    </TableCell>
                    <TableCell sx={styles.bodyText}>50 SAR</TableCell>
                    <TableCell sx={styles.bodyText}>0</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    <div
                    //   className={classes.noData}
                    >
                      No Records
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={{ ...styles.actionContainer, ...styles.btnContainer }}>
        <Box>
          <LinkButton
            // href={routes.CREATE_CASE}
            onClick={() => navigate(-1)}
            sx={{ position: 'relative', right: '5px' }}
            title="Back"
            externalStyles={styles.linkBtn}
            startIcon={<Box component="img" src={LocalImages.arrow_left} alt="arrow" />}
          />
          <Typography sx={styles.heading}>Transaction ID: {paramId[1]}</Typography>
        </Box>
        <Box sx={styles.selectContainer}>
          <ContainedButton
            title="View Retrieval Request"
            externalStyles={styles.validate}
            size="large"
            variant="contained"
            fullWidth
            noShadow
          />
          <ContainedButton
            title="Validate & Process"
            externalStyles={styles.btn}
            size="large"
            variant="contained"
            fullWidth
            noShadow
          />
        </Box>
      </Box>

      <Box sx={{ ...styles.actionContainer, ...styles.transContainer }}>
        <Box sx={{ ...styles.actionContainer, ...styles.whiteBox }}>
          <Box>
            <Typography sx={styles.transDetail}>Card Member Number</Typography>
            <Typography sx={styles.transDetail}>SE Number</Typography>
            <Typography sx={styles.transDetail}>SE Name</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.transDetail, ...styles.transData }}>
              13221XXXX1313
            </Typography>
            <Typography sx={{ ...styles.transDetail, ...styles.transData }}>1937971937</Typography>
            <Typography sx={{ ...styles.transDetail, ...styles.transData }}>Netflix.com</Typography>
          </Box>
        </Box>
        <Box sx={{ ...styles.actionContainer, ...styles.whiteBox }}>
          <Box>
            <Typography sx={styles.transDetail}>Transaction Date</Typography>
            <Typography sx={styles.transDetail}>Transaction Type</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.transDetail, ...styles.transData }}>11/02/2021</Typography>
            <Typography sx={{ ...styles.transDetail, ...styles.transData }}>04:35:02</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ ...styles.actionContainer, ...styles.lifeCycle }}>
        <Typography sx={styles.heading}>Transaction Lifecycle</Typography>
        <ContainedButton
          title="Download"
          externalStyles={{ ...styles.btn, ...styles.validate }}
          size="large"
          variant="contained"
          endIcon={<Box component="img" src={LocalImages.DOWNLOAD_ICON} alt="download" />}
          // fullWidth
          noShadow
        />
      </Box>

      {<LifeCycle />}
    </Box>
  );
};

export default CreateCaseDetail;
