import React from 'react';
import { Navigate } from 'react-router-dom';
import ACCOUNT_INFO from '../constants/info';
import routes from '../constants/routes';
import { getAccessToken } from '../constants/session';
import Helpers from '../helpers';

const PublicRoute = ({ children }: any) => {
  return getAccessToken() ? (
    Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator ? (
      <Navigate to={routes.DASHBOARD_TP_BANK} />
    ) : (
      <Navigate to={routes.DASHBOARD_AESA} />
    )
  ) : (
    children
  );
};

export default PublicRoute;
