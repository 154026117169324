import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Modal,
  Button,
  Tooltip,
  Popover,
} from '@mui/material';
import { Box } from '@mui/system';
import { useIdleTimer } from 'react-idle-timer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import colorConstant from '../../constants/colorConstant';
import LocalImages from '../../constants/localImages';
import { logoutApi, getProfile } from '../../pages/login/services';
import useLoginHelper from '../../pages/login/helper';
import routes from '../../constants/routes';
import { useSelector } from 'react-redux';
import { ReducersState } from '../../redux/rootReducer';
import Helpers, { checkIfDateValueExists, checkIfValueExists } from '../../helpers';
import ACCOUNT_INFO from '../../constants/info';
import moment from 'moment';
import { Cancel, FiberManualRecord } from '@mui/icons-material';
import ACTION_NAMES from '../../constants/actionNames';
import {
  deleteNotification,
  deleteNotificationAll,
  getNotificationData,
  getNotificationUnreadCount,
} from './services';
import ConfirmationPopup from '../modal/confirmationPopup';
import SimpleModal from '../modal';
import { Link } from 'react-router-dom';
import styles from './style';

/**
 * for checking value
 * @param value
 * @returns
 */
const checkValueExist = (value: any) => {
  if (value) {
    return 'simple-popover';
  }
  return undefined;
};

function MainHeader({ headerText }: any) {
  const getLogoutTime = () => {
    const timeInMins = process.env.REACT_APP_IDLE_LOGOUT_MINS || '20';

    const parsedTimeInMins = parseInt(timeInMins);
    if (parsedTimeInMins) {
      return parsedTimeInMins;
    } else {
      return 20;
    }
  };

  const onIdle = () => {
    Helpers.logoutManual(dispatch, navigate);
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * getLogoutTime(),
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  const { userName, profilePicture } = useSelector(
    (state: ReducersState) => state.UserProfileReducer
  );
  const { isLoading } = useSelector((state: ReducersState) => state.GlobalReducer);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNot, setAnchorElNot] = useState<HTMLButtonElement | null>(null);
  const openProfile = Boolean(anchorEl);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const { dispatch, navigate } = useLoginHelper();
  const [counter, setCounter] = useState(10);
  const isNotification = Boolean(anchorElNot);
  const idNotification = checkValueExist(isNotification);

  /**
   * function to open profile
   * @param event
   */
  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { notificationData, count, page, limit, unReadCount } = useSelector(
    (state: ReducersState) => state.UserNotificationReducer
  );

  useEffect(() => {
    dispatch({
      type: ACTION_NAMES.USER_NOTIFICATIONS,
      payload: {
        notificationData: [],
        page: 1,
        limit: 10,
        count: 0,
        unReadCount: 0,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getNotificationUnreadCount());
  }, [dispatch]);

  const totalPages = Math.ceil(count / limit);

  /**
   * function for fetching notification data & to open notification on bell icon
   * @param event
   */
  const handleClickNotification = (event: any) => {
    setAnchorElNot(event.currentTarget);
    setAnchorEl(null);
    dispatch(getNotificationData());
    dispatch({
      type: ACTION_NAMES.USER_NOTIFICATIONS,
      payload: {
        unReadCount: 0,
      },
    });
  };

  /**
   * function to close notification
   */
  const handleCloseNotification = () => {
    setAnchorElNot(null);
    dispatch({
      type: ACTION_NAMES.USER_NOTIFICATIONS,
      payload: {
        notificationData: [],
        page: 1,
        limit: 10,
        count: 0,
      },
    });
  };

  /**
   * for deleting all the notifications
   */
  const clearAllNotification = () => {
    dispatch(deleteNotificationAll());
    setOpenDeletePopup(false);
    setAnchorElNot(null);
  };

  /**
   * for fetching more notifications
   */
  const handleClickMoreNotification = () => {
    setCounter(counter + 1 + limit);
    if (page < totalPages) {
      dispatch({
        type: ACTION_NAMES.USER_NOTIFICATIONS,
        payload: {
          page: page + 1,
        },
      });
      dispatch(getNotificationData());
    }
  };

  /**
   * for closing notification of bell icon
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * to display notification details on bell icon
   * @param notificationData
   * @param setOpenDeletePopup
   * @param handleClickMoreNotification
   * @param page
   * @param totalPages
   * @returns notification details
   */
  const notificationDetail = (
    notificationData: any,
    setOpenDeletePopup: any,
    handleClickMoreNotification: any,
    page: any,
    totalPages: any
  ) => {
    return (
      <Box sx={styles.popoverNotContainer}>
        <Box sx={styles.clearAllDiv}>
          <Box sx={styles.notificationText}>Notifications</Box>

          {notificationData?.length > 0 && (
            <Box aria-hidden sx={styles.clearAll} onClick={() => setOpenDeletePopup(true)}>
              Clear All
            </Box>
          )}
        </Box>
        <Divider sx={styles.dividerStyl} />
        <Box sx={styles.internalNotificationBox}>
          {notificationData && notificationData.length > 0 ? (
            notificationData.map((row: any, index: number) => (
              <Box sx={styles.notification} key={row.id}>
                <div aria-hidden>
                  <FiberManualRecord sx={{ color: '#B6B9BC !important' }} />
                </div>

                {row.notificationType === ACCOUNT_INFO.NOTIFICATION_TYPE.CASE_NOTIFICATION ? (
                  <Box aria-hidden style={{ flex: 0.9, margin: '0 0 0 5px' }}>
                    <Box sx={styles.title}>{row.title}</Box>
                    {row?.otherInformation?.messageType ? (
                      <>
                        <Box sx={styles.otherInfoContainer}>
                          <p>
                            Network Processing Date:{' '}
                            <span style={{ fontWeight: '600', color: '#1A202D' }}>
                              {checkIfDateValueExists(row?.otherInformation?.networkProcessingDate)}
                            </span>
                          </p>
                        </Box>

                        <Box sx={styles.otherInfoContainer}>
                          <p>
                            Message Type:{' '}
                            <span style={{ fontWeight: '600', color: '#1A202D' }}>
                              {row?.otherInformation?.messageType
                                ? row?.otherInformation?.messageType
                                : '-'}
                            </span>
                          </p>
                        </Box>

                        <Box sx={styles.otherInfoContainer}>
                          <p>
                            Message Reason Code:{' '}
                            <span style={{ fontWeight: '600', color: '#1A202D' }}>
                              {row?.otherInformation?.messageReasonCode
                                ? row?.otherInformation?.messageReasonCode
                                : '-'}
                            </span>
                          </p>
                        </Box>

                        <Box sx={styles.otherInfoContainer}>
                          <p>
                            Amount:{' '}
                            <span style={{ fontWeight: '600', color: '#1A202D' }}>
                              {checkIfValueExists(row?.otherInformation?.amount)}
                            </span>
                          </p>
                        </Box>

                        <Box sx={styles.otherInfoContainer}>
                          <p>
                            Itemized Document Type:{' '}
                            <span style={{ fontWeight: '600', color: '#1A202D' }}>
                              {checkIfValueExists(row?.otherInformation?.itemizedDocumentType)}
                            </span>
                          </p>
                        </Box>
                      </>
                    ) : (
                      ''
                    )}
                    {row.createdAt && (
                      <Box sx={styles.createdDt}>
                        {moment(row.createdAt).format('YYYY-MM-DD HH:mm')}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box aria-hidden style={{ flex: 0.9, margin: '0 0 0 5px' }}>
                    <Box sx={styles.title}>{row.title}</Box>
                    <Box sx={styles.description}>{checkIfValueExists(row.description)}</Box>

                    {row.createdAt && (
                      <Box sx={styles.createdDt}>
                        {moment(row.createdAt).format('YYYY-MM-DD HH:mm')}
                      </Box>
                    )}
                  </Box>
                )}

                <Box sx={styles.deleteNotification}>
                  <Cancel
                    onClick={() => {
                      dispatch(deleteNotification(row.id, index));
                    }}
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={styles.noRecords}>{isLoading ? 'Loading...' : 'No records'}</Box>
          )}
        </Box>

        {page < totalPages ? (
          <Box aria-hidden sx={styles.readMoreBtn}>
            <Button sx={styles.readMore} onClick={handleClickMoreNotification}>
              Read more
            </Button>
          </Box>
        ) : (
          <Box sx={styles.readMore} />
        )}
      </Box>
    );
  };

  /**
   * Modal of log out
   * @returns logout the user on clicking yes
   */
  const logoutModal = () => {
    return (
      <Box sx={styles.logoutContaner}>
        <ErrorRoundedIcon />
        <Typography sx={styles.logoutHeading}>Are you sure?</Typography>
        <Typography sx={styles.logoutText}>
          {' '}
          Do you really want to logout? You will need to sign in again to continue{' '}
        </Typography>
        <Box sx={styles.logoutButtonContainer}>
          <Button onClick={() => setOpenLogout(false)} size="large" variant="outlined">
            Close
          </Button>
          <Button
            onClick={() => dispatch(logoutApi(navigate))}
            size="large"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Logout
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.mainHeaderContainer}>
      <Box sx={styles.logoHeadingContainer}>
        <Link to="/">
          <Box
            component="img"
            src={LocalImages.MAIN_MENU_LOGO}
            alt="MAIN_MENU_LOGO"
            sx={styles.headerLogo}
          />
        </Link>
        <Typography sx={styles.headerTextStyle}>{headerText}</Typography>
        <span className="button-tooltip-container">
          <span id="custom-tooltip">Copied to Clipboard!</span>
          <textarea className="visuallyhidden" id="box"></textarea>
        </span>
      </Box>
      <Box sx={styles.notificationProfileContainer}>
        <Badge badgeContent={unReadCount} color="error" overlap="circular">
          <Avatar
            sx={{
              width: 'unset',
              height: 'unset',
              cursor: 'pointer',
            }}
            alt="Bell_Logo"
            src={LocalImages.BELL_LOGO}
            onClick={handleClickNotification}
          />
        </Badge>
        <Popover
          id={idNotification}
          open={isNotification}
          anchorEl={anchorElNot}
          disableScrollLock
          onClose={handleCloseNotification}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {notificationDetail(
            notificationData,
            setOpenDeletePopup,
            handleClickMoreNotification,
            page,
            totalPages
          )}
        </Popover>
        <SimpleModal
          isOpen={openDeletePopup}
          handleOpen={() => setOpenDeletePopup(true)}
          body={
            <ConfirmationPopup
              warningMessage={'Are you sure you want to delete all notifications?'}
              onConfirm={() => clearAllNotification()}
              actionText={'Delete'}
              handleClose={() => setOpenDeletePopup(false)}
              closeText={'Cancel'}
            />
          }
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Avatar sx={{ width: '3.333vw', height: '3.333vw' }} alt={userName} src={profilePicture} />
        <Typography onClick={handleClickProfile} sx={styles.profileText}>
          <Tooltip title={userName}>
            <span>{userName}</span>
          </Tooltip>
          <KeyboardArrowDownIcon />
        </Typography>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={openProfile}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClickCapture={() => navigate(routes.userProfilePath)}
            sx={styles.userMenuFont}
            onClick={handleClose}
          >
            <PersonOutlineOutlinedIcon color="primary" />
            View Profile
          </MenuItem>
          <MenuItem
            sx={styles.userMenuFont}
            onClickCapture={() => navigate(routes.CHANGE_PASSWORD)}
            onClick={handleClose}
          >
            <LockOutlinedIcon color="primary" />
            Change Password
          </MenuItem>
          {Helpers.getAccountType() !== ACCOUNT_INFO.ROLES.superAdmin ? (
            <MenuItem
              sx={styles.userMenuFont}
              onClickCapture={() => navigate(routes.CONTACT_US)}
              onClick={handleClose}
            >
              <ContactPageIcon color="primary" />
              Contact Us
            </MenuItem>
          ) : (
            ''
          )}
          <MenuItem
            onClickCapture={() => setOpenLogout(true)}
            sx={styles.userMenuFont}
            onClick={handleClose}
          >
            {' '}
            <LogoutIcon sx={{ color: colorConstant.iconRed }} />
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <Modal open={openLogout} onClose={() => setOpenLogout(false)}>
        {logoutModal()}
      </Modal>
    </Box>
  );
}

export default MainHeader;
