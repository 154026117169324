import React from 'react';
import { Box } from '@mui/material';
import LocalImages from '../../constants/localImages';

interface Props {
  childComp: React.ReactNode;
}

const LoginWrapper = ({ childComp }: Props) => {
  const styles = {
    mainContainer: {
      display: 'flex',
      height: '100vh',
    },
    signInBox: {
      padding: '2.083vw 4.167vw 0 4.167vw',
      width: '40%',
    },
    bannerBox: {
      position: 'relative',
      width: '60%',
    },
    banner: {
      height: '100%',
      backgroundImage: `url(${LocalImages.AMEX_BANNER})`,
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
    },
    lightAMEX_LOGO: {
      zIndex: '1',
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
  };

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.signInBox}>
        <Box component="img" src={LocalImages.AMEX_LOGO} alt="amex_logo" />
        {childComp}
      </Box>
      <Box sx={styles.bannerBox}>
        <Box component="div" sx={styles.banner} />

        <Box component="div" sx={styles.lightAMEX_LOGO}>
          <img src={LocalImages.light_amex_logo} alt="amex_logo" />
        </Box>
      </Box>
    </Box>
  );
};

export default LoginWrapper;
