import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../../constants/actionNames';

export class UserManageTPBankState {
  // current type for listing of users
  type: number = 1;
  listingType: number = 2;

  // listing for users
  listingData = [];
  userListCount: number = 0;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListPageCount: number = 1;

  // audit log
  auditListData = [];
  auditListCount: number = 0;
  auditListPage: number = 1;
  auditListLimit: number = 10;
  auditListPageCount: number = 1;

  selectedData: Array<string> = [];
  userName: string = '';
  userEmail: string = '';
  createdAt: string = '';
  contactNumber: string = '';
  countryCode: string = '';
  userId: string = '';
  passCode: string = '';
  userStatus: number = 1;
  accountType: number = 0;
  bankDocId: string = '';
  bankId: string = '';
  bankName: string = '';
  bankSubId = [];
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = 0;
  isFilter: boolean = false;
  currentSortKey = 'createdAt';
  isCurrentSortAscending = true;
  dial_code: string = '';
}

export const UserManageTPBankReducer = (
  state: UserManageTPBankState = new UserManageTPBankState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.USER_MANAGE_TP_BANK) {
    return { ...state, ...action.payload };
  }
  return state;
};
