import ACTION_NAMES from '../../constants/actionNames';
import ENDPOINTS from '../../constants/endpoints';
import { checkResponseStatus } from '../../helpers';
import { API } from '../../middlewares';
import { updateGlobalLoader } from '../../redux/globalActions';
import showAlert from '../alert';

/**
 * for getting notification unread count
 * @returns unread count or error
 */
export const getNotificationUnreadCount = () => (dispatch: any, getState: any) => {
  dispatch(updateGlobalLoader(true));
  API.get(ENDPOINTS.notificationCount)
    .then((response: any) => {
      dispatch({
        type: ACTION_NAMES.USER_NOTIFICATIONS,
        payload: {
          unReadCount: response.data?.data,
        },
      });
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * for getting notification data
 * @returns notification data or error
 */
export const getNotificationData = () => (dispatch: any, getState: any) => {
  dispatch(updateGlobalLoader(true));

  const { page, limit, notificationData } = getState().UserNotificationReducer;

  const dataToSend = {
    params: {
      page,
      limit,
    },
  };

  API.get(ENDPOINTS.userNotification, dataToSend)
    .then((response: any) => {
      dispatch({
        type: ACTION_NAMES.USER_NOTIFICATIONS,
        payload: {
          notificationData: [...notificationData, ...response.data?.data?.data],
          count: response.data?.data?.count,
        },
      });
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * for clearing all notifications
 * @returns empty notification data
 */
export const deleteNotificationAll = () => (dispatch: any, getState: any) => {
  dispatch(updateGlobalLoader(true));

  API.delete(ENDPOINTS.deleteNotificationAll)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        showAlert(1, 'Notifications deleted successfully');
        dispatch({
          type: ACTION_NAMES.USER_NOTIFICATIONS,
          payload: {
            notificationData: [],
            page: 1,
            limit: 10,
            count: 0,
            unReadCount: 0,
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

/**
 * for deleting selected notification
 * @param selectedIds
 * @param index
 * @returns updated notification data after deleting selected notification
 */
export const deleteNotification =
  (selectedIds?: any, index?: any) => (dispatch: any, getState: any) => {
    dispatch(updateGlobalLoader(true));

    API.delete(`${ENDPOINTS.deleteNotification}${selectedIds}`)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          showAlert(1, 'Notification deleted successfully');
          dispatch({
            type: ACTION_NAMES.USER_NOTIFICATIONS,
            payload: {
              notificationData: [],
            },
          });
          dispatch(getNotificationData());
        }
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        showAlert(2, response?.data?.message);
        dispatch(updateGlobalLoader(false));
      });
  };
