import ACTION_NAMES from '../../../constants/actionNames';

export class TemplateNotificationState {
  templateNotificationListingData = [];
  templateNotificationCount: number = 0;
  templateLogList = [];
  templateLogLimit: number = 10;
  templateLogPageCount: number = 1;
  templateLogPage: number = 1;
  templateLogCount: number = 0;
  /* 
  templateDetail variable start
 */
  templateId: string = '';
  templateTitle: string = '';
  notResponded: number = 0;
  templateType: number = 0;
  audienceTypes: number = 0;
  notificationType: number = 0;
  lastUpdatedon: number = 0;
  templateDescription: string = '';
  notificationChannel: number = 0;
  messageType: number | null = null;
  /* 
  templateDetail variable end
 */
}

export const TemplateNotificationReducer = (
  state: TemplateNotificationState = new TemplateNotificationState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.MANAGE_TEMPLATE_NOTIFICATIONS) {
    return { ...state, ...action.payload };
  }
  return state;
};
