import React from 'react';
import MainMenu from '../menu';
import MainHeader from '../header';
import { Box } from '@mui/system';
import { Backdrop } from '@mui/material';
import Spinner from '../spinner';
import { useSelector } from 'react-redux';
import { ReducersState } from '../../redux/rootReducer';
import { getStorageItem, getUserIdToken, setStorageItem } from '../../helpers';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './style';

export default function PrivateContainer({ children, headerText }: any) {
  const { isLoading } = useSelector((state: ReducersState) => state.GlobalReducer);

  // Logging out the user from AMEX if the user logs out from any tab
  const location = useLocation();

  window.addEventListener('storage', (e) => {
    if (
      e.key === 'accessToken'
    ) {
      window.location.reload();
    }
  });

  if (getUserIdToken()) {
    return (
      <Box sx={styles.universalComp_mainContainer}>
        <Backdrop open={isLoading || false}>
          <Spinner />
        </Backdrop>
        <MainHeader headerText={headerText} />
        <Box sx={styles.universalComp_menuContainer}>
          <MainMenu />
          <Box sx={styles.rightContainer}>{children}</Box>
        </Box>
      </Box>
    );
  }
  const pathname = window.location.pathname + window.location.search;
  const token = getStorageItem('accessToken');

  if (!token && pathname !== '/') {
    setStorageItem('redirectTo', pathname);
  }
  return <Navigate to="/" state={{ from: location }} />;
}
