import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import LocalImages from '../../constants/localImages';
import styles from './styles';

interface Props {
  handleSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder: string;
  value: string;
}
function SearchBox({ handleSearch, placeholder, value }: Props) {
  const classes = styles();

  return (
    <TextField
      key="search"
      className={classes.searchInput}
      variant="outlined"
      value={value}
      placeholder={placeholder}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <div style={{ display: 'flex' }}>
              <img alt="Search" src={LocalImages.SEARCH_ICON} className={classes.searchIcon} />
              <span style={{ border: '0.5px solid #DDDDE1', marginLeft: '10px' }}></span>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default React.memo(SearchBox);
