import ActionName from '../constants/actionNames';

export class GlobalState {
  isLoading = false;

  appDrawerData: any = [];

  customerData: any = [];
}

export const GlobalReducer = (state: GlobalState = new GlobalState(), action: any = {}) => {
  switch (action.type) {
    case ActionName.IS_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

export default GlobalReducer;
