import { makeStyles, createStyles } from '@mui/styles';

export default makeStyles(() =>
  createStyles({
    searchInput: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      width: '100%',
      '& .MuiInputBase-root': {
        fontFamily: 'BentonSans',
        fontWeight: 550,
        fontSize: '0.99vw',
        lineHeight: '1.250vw',
        marginRight: '10px',
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.24)',
        },
      },
      '& input': {
        padding: '16.5px 0',
        '&::placeholder': {
          color: '#515151',
          opacity: 0.85,
        },
      },
    },
    searchIcon: {
      cursor: 'pointer',
    },
  })
);
