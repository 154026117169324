import React, { useEffect } from 'react';
import { List, ListItemButton, ListItemIcon, Box, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation, Link } from 'react-router-dom';
import Helpers from '../../helpers';
import ACCOUNT_INFO from '../../constants/info';
import { styles } from './style';
import routes from '../../constants/routes';
import LocalImages from '../../constants/localImages';
// import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

function MainMenu() {
  const [openSubMenu, setOpenSubMenu] = React.useState(false);

  /**
   * to open subMenu if any list contains subMenu
   */
  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  let location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (
      pathname === routes.MANAGER_MANAGE ||
      pathname === routes.OPERATOR_MANAGE ||
      pathname === routes.TP_BANK_SUPER_ADMIN ||
      pathname === routes.TP_BANK_MANAGER ||
      pathname === routes.TP_BANK_OPERATOR
    ) {
      setOpenSubMenu(true);
    }
  }, [pathname]);

  /**
   * Button used in side menu listing
   * @param menu
   * @returns button of list
   */
  const listButtonForMenu = (menu: any) => (
    <ListItemButton
      onClick={() => {
        if (menu.subMenu) {
          handleClick();
        }
      }}
      selected={
        !menu.subMenu ? menu.route === location.pathname || !!menu.isNestedRouteSelected : false
      }
      sx={styles.menuButton}
    >
      <ListItemIcon>
        <>
          {menu.isComponentIcon ? (
            <Box sx={{ ...styles.menuLogo, ...styles.reactIcon }}>{menu.icon}</Box>
          ) : (
            <Box component="img" src={menu.icon} sx={styles.menuLogo} alt="icon" />
          )}
        </>
      </ListItemIcon>
      <ListItemText sx={styles.menuTextStyle} primary={menu.title} />
      {menu.subMenu ? (
        openSubMenu ? (
          <ExpandLess sx={styles.iconColor} />
        ) : (
          <ExpandMore sx={styles.iconColor} />
        )
      ) : (
        ''
      )}
    </ListItemButton>
  );

  const isNestedRouteSelected = (selectedRoutes: string[]) => {
    return selectedRoutes.includes(location.pathname);
  };

  /**
   * Array of menu Items
   */
  const menuItems = [
    {
      title: 'Dashboard',
      icon: LocalImages.MENU_HOME,
      route:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator
          ? routes.DASHBOARD_TP_BANK
          : routes.DASHBOARD_AESA,
      isShow: true,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.DASHBOARD_TP_BANK,
        routes.DASHBOARD_AESA,
      ]),
    },
    {
      title: 'User Management',
      icon: LocalImages.MENU_USER,
      route: '',
      isShow: Helpers.getAccountType() !== ACCOUNT_INFO.ROLES.operator,
      subMenu: [
        {
          title: 'AESA',
          route: routes.MANAGER_MANAGE,
          isShow: true,
          isNestedRouteSelected: isNestedRouteSelected([
            routes.MANAGER_MANAGE,
            routes.OPERATOR_MANAGE,
            routes.managerDetails,
            routes.operatorDetails,
            routes.EDIT_MANAGER,
            routes.EDIT_OPERATOR,
            routes.ADD_MANAGER,
            routes.ADD_OPERATOR,
          ]),
        },
        {
          title: 'TP Bank',
          route: routes.TP_BANK_SUPER_ADMIN,
          isShow: true,
          isNestedRouteSelected: isNestedRouteSelected([
            routes.TP_BANK_SUPER_ADMIN,
            routes.TP_BANK_MANAGER,
            routes.TP_BANK_OPERATOR,
            routes.TP_BANK_USER_DETAILS,
          ]),
        },
      ],
    },
    {
      title: 'TP Bank Management',
      icon: LocalImages.MENU_USER,
      route: routes.TP_BANK_SUPER_ADMIN,
      isShow: Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.TP_BANK_SUPER_ADMIN,
        routes.TP_BANK_MANAGER,
        routes.TP_BANK_OPERATOR,
        routes.TP_BANK_USER_DETAILS,
        routes.ADD_SUPER_ADMIN,
      ]),
    },
    {
      title: 'TP Banks',
      icon: LocalImages.TPBANK_IMAGE,
      route: routes.TP_BANK,
      isShow: true,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.TP_BANK_Detail,
        routes.TP_BANK,
        routes.EDIT_TP_BANK,
        routes.ADD_TP_BANK,
        routes.ARCHIVED_TP_BANKS,
      ]),
    },
    {
      title: 'Create Case',
      icon: LocalImages.MENU_CASE,
      route: routes.CREATE_CASE,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.CREATE_CASE,
        routes.CORE_CASE,
        routes.SEARCH_IN_CORE_SYSTEM,
        routes.CREATE_CASE_MANAGER,
        routes.CREATE_CASE_OPERATOR,
      ]),
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.manager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator,
    },
    {
      title: 'Case Inbox',
      icon: LocalImages.MENU_INBOX,
      route: routes.CASE_INBOX_RECEIVED,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.CASE_INBOX_SENT,
        routes.CASE_INBOX_RECEIVED,
        routes.CASE_INBOX_CLOSED,
        routes.CASE_INBOX,
      ]),
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.manager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator,
    },
    {
      title: 'Audit Log',
      icon: LocalImages.MENU_AUDIT,
      route: routes.AUDIT_LOG,
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.manager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.superAdmin,
    },
    {
      title: 'Manage Notifications',
      icon: LocalImages.NOTIFICATION_WHITE,
      route: routes.MANAGE_NOTIFICATIONS,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.MANAGE_NOTIFICATIONS,
        routes.TEMPLATES_DETAIL,
        routes.EDIT_TEMPLATE,
        routes.ADD_NOTIFICATION,
        routes.MANAGE_TEMPLATES,
      ]),
      isShow: true,
    },
    // {
    //   title: 'Transaction Details',
    //   // icon: LocalImages.MENU_INBOX,
    //   icon: <ReceiptLongOutlinedIcon color="secondary" />,
    //   isComponentIcon: true,
    //   isNestedRouteSelected: isNestedRouteSelected([
    //     routes.TRANSACTION_DETAILS,
    //     routes.AUTH_TRANSACTION_DETAILS,
    //   ]),
    //   route: routes.TRANSACTION_DETAILS,
    //   isShow:
    //     Helpers.getAccountType() === ACCOUNT_INFO.ROLES.manager ||
    //     Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator,
    // },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <List sx={styles.mainMenuContainer}>
        {menuItems.map((menu) => {
          return (
            <div style={!menu.isShow ? { display: 'none' } : {}}>
              {menu.subMenu ? (
                listButtonForMenu(menu)
              ) : (
                <Link to={menu.route}>{listButtonForMenu(menu)}</Link>
              )}

              {menu.subMenu ? (
                <>
                  <Collapse
                    in={
                      Helpers.getAccountType() === ACCOUNT_INFO.ROLES.operator ? false : openSubMenu
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menu.subMenu.map((subMenu) => (
                        <Link to={subMenu.route}>
                          <ListItemButton
                            selected={
                              subMenu.route === location.pathname || !!subMenu.isNestedRouteSelected
                            }
                            sx={styles.menuButton}
                          >
                            <ListItemIcon sx={{ marginLeft: '10%' }}></ListItemIcon>
                            <ListItemText sx={styles.menuTextStyle} primary={subMenu.title} />
                          </ListItemButton>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </List>
    </Box>
  );
}

export default MainMenu;
