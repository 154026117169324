import { Divider, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContainedButton from '../../components/buttons/contained-button';
import LinkButton from '../../components/buttons/link-button';
import SearchBox from '../../components/searchBox';
import SearchInCoreListing from '../../components/tables/searchInCoreListing';
import ACTION_NAMES from '../../constants/actionNames';
import LocalImages from '../../constants/localImages';
import useCreateCaseHelper from '../createCase/helper';
import useSearchCoreSystemHelper from './helper';
import { searchCoreCaseListing } from './services';
import styles from './style';

const SearchInCoreSystem = () => {
  const { handleSearch, caseListSearch } = useSearchCoreSystemHelper();
  const { dispatch } = useCreateCaseHelper();

  const [searchValue, setSearchValue] = useState(2);

  const options = [
    { id: 2, value: 'ARN Number' },
    { id: 1, value: 'Transaction Id' },
    { id: 3, value: 'SE Number' },
    { id: 4, value: 'Case Id' },
  ];

  useEffect(() => {
    dispatch({
      type: ACTION_NAMES.SEARCH_IN_CORE,
      payload: {
        caseListingData: [],
        caseListCount: 0,
        caseListSearch: '',
        caseListPage: 1,
        caseListLimit: 10,
      },
    });
  }, [dispatch]);

  const searchCheck = (searchBy: number) => {
    switch (searchBy) {
      case 2:
        return 'Enter ARN Number';
      case 1:
        return 'Enter transaction id';
      case 3:
        return 'Enter SE Number';
      case 4:
        return 'Enter Case Id';
      default:
        return 'Enter transaction id';
    }
  };

  const handleSearchBy = (event: any) => {
    setSearchValue(event.target.value);
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={styles.mainContainer}>
        <Box sx={{ ...styles.actionContainer, ...styles.btnContainer }}>
          <Box>
            <LinkButton
              onClick={() => navigate(-1)}
              sx={{ position: 'relative', right: '5px' }}
              title="Back"
              externalStyles={styles.linkBtn}
              startIcon={<Box component="img" src={LocalImages.arrow_left} alt="arrow" />}
            />
            <Typography sx={styles.heading}>Search in Core System</Typography>
          </Box>
        </Box>
        <Box sx={styles.searchContainer}>
          <Box sx={styles.searchBy}>
            <Typography>Search By</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <TextField
              id="standard-select-currency"
              select
              defaultValue={options[0].id}
              variant="standard"
              sx={styles.selectFilter}
              onChange={handleSearchBy}
            >
              {options.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={styles.searchBox}>
            <SearchBox
              placeholder={searchCheck(searchValue)}
              handleSearch={handleSearch}
              value={caseListSearch}
            />
            <ContainedButton
              title="Search"
              externalStyles={styles.btn}
              size="large"
              variant="contained"
              disabled={caseListSearch.length === 0}
              onClick={() => dispatch(searchCoreCaseListing(searchValue))}
            />
          </Box>
        </Box>
        <Box sx={styles.mainTableContainer}>
          <Box sx={styles.innerContainer} />

          <SearchInCoreListing
            isSearchCase={true}
            userListSearch={caseListSearch}
            searchValue={searchValue}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchInCoreSystem;
