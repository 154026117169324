import { Box } from '@mui/system';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { COLORS } from '../../constants/colors';
import { Form, Formik } from 'formik';
import FormValidation from '../../formValidation';
import useLoginHelper from './helper';
import TextFieldWrapper from '../../components/inputs/textfield-wrapper/text.field.wrapper';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import routes from '../../constants/routes';
import LocalImages from '../../constants/localImages';
import ContainedButton from '../../components/buttons/contained-button';
import { getIPInformation, loginApi } from './services';
import { useEffect } from 'react';
function Login() {
  const styles = {
    mainContainer: {
      padding: '3.472vw 0 0 0',
    },
    heading: {
      font: 'normal normal 700 2.222vw/2.014vw BentonSans',
    },
    caption: {
      font: 'normal normal 550 1.250vw/1.181vw BentonSans',
      color: COLORS.grey,
      padding: '1.736vw 0 1.389vw 0',
    },
    email: {
      margin: '1.528vw 0 2.778vw 0',
    },
    inputIcons: {
      padding: '0.347vw',
    },
    forgetPassword: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      font: 'normal normal 400 1.250vw/1.736vw Avenir',
      color: COLORS.btnColor,
    },
    btnContainer: {
      '& .MuiButton-root.Mui-disabled': {
        backgroundColor: `${COLORS.primary} !important`,
        opacity: '0.4',
        color: `${COLORS.white} !important`,
      },
    },
    btn: {
      margin: '3.611vw 0 2.917vw 0',
      backgroundColor: COLORS.primary,
      color: COLORS.white,
      fontFamily: 'Avenir !important',
      fontWeight: 900,
      fontStyle: 'normal !important',
      fontSize: '1.5em !important',
      // font: 'normal normal 900 1.667vw/2.292vw Avenir',
      '&:hover': {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
      },
    },
    clearIcon: {
      margin: '0 0.347vw',
    },
  };

  const {
    loginInitialValue,
    handleClearPassword,
    navigate,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleChangePassword,
    dispatch,
  } = useLoginHelper();

  useEffect(() => {
    dispatch(getIPInformation());
  });

  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={styles.heading}>Chargeback Portal Login</Typography>
      <Typography sx={styles.caption}>Use your credentials to login</Typography>
      <Formik
        initialValues={{ ...loginInitialValue }}
        validationSchema={FormValidation.LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(loginApi(values, setSubmitting, navigate));
        }}
      >
        {({ isValid, values, setValues, dirty, isSubmitting }) => {
          return (
            <Form autoComplete="off">
              <Box>
                <TextFieldWrapper
                  sx={styles.email}
                  name="email"
                  autoComplete="off"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          sx={styles.inputIcons}
                          src={LocalImages.EMAIL_ICON}
                          alt="email"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextFieldWrapper
                  name="password"
                  id="password"
                  autoComplete="offawd"
                  placeholder="Password"
                  value={values.password}
                  onChange={(e: any) => handleChangePassword(e, setValues)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          sx={styles.inputIcons}
                          src={LocalImages.passwordIcon}
                          alt="password"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e: any) => handleClearPassword(e, setValues)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={styles.clearIcon}
                        >
                          <Box component="img" src={LocalImages.CLEAR_ICON} alt="clear" />
                        </IconButton>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword()}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Login"
                  disabled={!isValid || isSubmitting}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                />
                <Typography
                  onClick={() => {
                    navigate(routes.forget_password);
                  }}
                  sx={styles.forgetPassword}
                >
                  Forgot Password?
                </Typography>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default Login;
