import { combineReducers } from 'redux';
import { UserManageState, UserManageReducer } from '../pages/userManage/reducer';
import { UserManageTPBankState, UserManageTPBankReducer } from '../pages/userManage/tpBank/reducer';
import { GlobalReducer, GlobalState } from './globalReducer';
import { UserProfileReducer, UserProfileData } from '../pages/userProfile/reducer';
import { LocalMachineReducer, LocalMachineData } from '../pages/login/reducer';
import { TpBankReducer, TpBankData } from '../pages/tpBank/reducer';
import { CreateCaseReducer, CreateCaseState } from '../pages/createCase/reducer';
import { CaseInboxState, CaseInboxReducer } from '../pages/caseInbox/reducer';
import { NotificationState, NotificationReducer } from '../pages/manageNotifications/reducer';
import { AuditLogReducer, AuditLogState } from './../pages/auditLog/reducer';
import { DashboarState, DashboardReducer } from '../pages/dashboard/reducer';
import { SearchInCoreReducer, SearchInCoreState } from './../pages/searchInCoreSystem/reducer';
import { UserNotificationReducer, UserNotificationState } from '../components/header/reducer';
import {
  TemplateNotificationState,
  TemplateNotificationReducer,
} from '../pages/manageNotifications/templates/reducer';
import {
  TransactionDetailsReducer,
  TransactionDetailsState,
} from '../pages/transactionDetails/reducer';

export class ReducersState {
  GlobalReducer: GlobalState = new GlobalState();
  UserProfileReducer: UserProfileData = new UserProfileData();
  LocalMachineReducer: LocalMachineData = new LocalMachineData();
  UserManageReducer: UserManageState = new UserManageState();
  UserManageTPBankReducer: UserManageTPBankState = new UserManageTPBankState();
  TpBankReducer: TpBankData = new TpBankData();
  CreateCaseReducer: CreateCaseState = new CreateCaseState();
  CaseInboxReducer: CaseInboxState = new CaseInboxState();
  NotificationReducer: NotificationState = new NotificationState();
  AuditLogReducer: AuditLogState = new AuditLogState();
  DashboardReducer: DashboarState = new DashboarState();
  UserNotificationReducer: UserNotificationState = new UserNotificationState();
  TemplateNotificationReducer: TemplateNotificationState = new TemplateNotificationState();
  SearchInCoreReducer: SearchInCoreState = new SearchInCoreState();
  TransactionDetailsReducer: TransactionDetailsState = new TransactionDetailsState();
}

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  GlobalReducer,
  UserProfileReducer,
  LocalMachineReducer,
  UserManageReducer,
  UserManageTPBankReducer,
  TpBankReducer,
  CreateCaseReducer,
  CaseInboxReducer,
  NotificationReducer,
  AuditLogReducer,
  DashboardReducer,
  UserNotificationReducer,
  TemplateNotificationReducer,
  SearchInCoreReducer,
  TransactionDetailsReducer,
});

export default rootReducer;
