import { Button, ButtonProps } from '@mui/material';
import styles from './style';

export type ContainedButtonProps = {
  title: string;
  noShadow?: boolean;
  externalStyles?: object;
  isShow?: boolean;
};

const ContainedButton = ({
  title,
  noShadow,
  externalStyles,
  isShow = true,
  ...otherProps
}: ContainedButtonProps & ButtonProps) => {
  return (
    <Button
      disableElevation
      variant="contained"
      sx={{
        ...(isShow ? {} : { display: 'none' }),
        ...styles.btn,
        ...externalStyles,
        ...(noShadow ? styles.btn1 : ''),
      }}
      {...otherProps}
    >
      {title}
    </Button>
  );
};

export default ContainedButton;
