import { Dayjs } from 'dayjs';
import ActionNames from '../../constants/actionNames';

export type SubIdsType = {
  isError: boolean;
  subId: string;
  errorText: string;
};

export class TpBankData {
  listingData = [];
  userListCount: number = 0;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListPageCount: number = 1;

  // audit log
  auditListData = [];
  auditListCount: number = 0;
  auditListPage: number = 1;
  auditListLimit: number = 10;
  auditListPageCount: number = 1;

  allBanksList = [];
  bankName: string = '';
  bankId: string = '';
  bankSubId: string = '';
  bankStatus: number = 0;
  userStatus: number = 1;
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = 0;
  isFilter: boolean = false;
  editTpBankSubIds: SubIdsType[] = [];
  initialTPBankSubIds: SubIdsType[] = [];
  editTpBankName: string = '';
  editTpBankId: string = '';
  currentSortKey = 'createdAt';
  isCurrentSortAscending = true;

  //  tp bank autocomplete filter
  listingFilterData = [];
  userListFilterCount: number = 0;
  userListFilterPage: number = 1;
  userListFilterPageCount: number = 0;
  userListFilterSearch: string = '';
}

export const TpBankReducer = (state: TpBankData = new TpBankData(), action: any = {}) => {
  if (action.type === ActionNames.TP_BANK_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
};

export default TpBankReducer;
