import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface FooterCardProp {
  onClick: Function;
}

export default function ContentCopyButton({ onClick }: FooterCardProp) {
  return (
    <ContentCopyIcon
      style={{
        cursor: 'pointer',
        marginLeft: '5px',
        fontSize: '0.8em',
        color: '#3F679C',
      }}
      onClick={() => onClick()}
    />
  );
}
