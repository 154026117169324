// Package imports
import { ThemeProvider } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

// utility imports
import lightTheme from '../components/theme';
import { getStorageItem } from '../helpers';
import routes from '../constants/routes';
import { API } from '../middlewares';
import ACCOUNT_INFO from '../constants/info';

// Public Route
import PublicRoute from './PublicRoute';

// Wrappers for app
import LoginWrapper from '../components/loginWrapper/index';
import PrivateContainer from '../components/privateContainer';
import IntermediateRoute from './IntermediateRoute';

// Page routes
import Login from '../pages/login';
import CreateCaseDetail from '../pages/createCase/createCaseDetail';
import SearchInCoreSystem from '../pages/searchInCoreSystem';

const AddCaseForm = lazy(() => import('../pages/createCase/addCaseForm'));
const ManageNotifications = lazy(() => import('../pages/manageNotifications'));
const AddNotifications = lazy(() => import('../pages/manageNotifications/addNotification'));
const TemplateDetail = lazy(() => import('../pages/manageNotifications/templates/templateDetail'));
const EditTemplate = lazy(() => import('../pages/manageNotifications/templates/editTemplate'));

const ForgetPassword = lazy(() => import('../pages/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/resetPassword'));
const CreatePassword = lazy(() => import('../pages/createPasswordForNewUser'));
const UserManage = lazy(() => import('../pages/userManage'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const UserProfile = lazy(() => import('../pages/userProfile'));
const UserDetail = lazy(() => import('../pages/userManage/userDetail'));
const AddUser = lazy(() => import('../pages/userManage/addUser'));
const TP_BANK_USER = lazy(() => import('../pages/userManage/tpBank'));
const NotFound = lazy(() => import('../components/pageNotFound'));
const UserDetailTPUser = lazy(() => import('../pages/userManage/tpBank/userDetail'));
const ChangePassword = lazy(() => import('../pages/userProfile/changePassword'));
const TpBank = lazy(() => import('../pages/tpBank'));
const TpBankDetails = lazy(() => import('../pages/tpBank/tpBankDetail'));
const AddSuperAdmin = lazy(() => import('../pages/userManage/tpBank/addSuperAdmin'));
const AddTPBANK = lazy(() => import('../pages/tpBank/addTpBank'));
const CreateCase = lazy(() => import('../pages/createCase'));
const CaseInbox = lazy(() => import('../pages/caseInbox'));
const CaseInboxDetail = lazy(() => import('../pages/caseInbox/caseInboxDetail'));
const AuditLog = lazy(() => import('../pages/auditLog'));
const ContactUs = lazy(() => import('../pages/contactUs'));
const RetrieveTPBank = lazy(() => import('../pages/tpBank/retrieveTPBank'));
// const TransactionDetails = lazy(() => import('../pages/transactionDetails'));

// list of routes
// Note: intermediate route behaves like public route but does not redirect to the dashboard when logged in
const routesList = [
  {
    path: routes.login,
    isPrivate: false,
    isIntermediate: false,
    component: <Login />,
  },
  {
    path: routes.forget_password,
    isPrivate: false,
    isIntermediate: true,
    component: <ForgetPassword />,
  },
  {
    path: routes.RESET_PASSWORD,
    isPrivate: false,
    isIntermediate: true,
    component: <ResetPassword />,
  },
  {
    path: routes.CREATE_PASSWORD,
    isPrivate: false,
    isIntermediate: true,
    component: <CreatePassword />,
  },
  {
    path: routes.DASHBOARD_AESA,
    isPrivate: true,
    component: <Dashboard type={ACCOUNT_INFO.DASHBOARD_TYPE.AESA} />,
    headerText: 'Dashboard',
  },
  {
    path: routes.DASHBOARD_TP_BANK,
    isPrivate: true,
    component: <Dashboard type={ACCOUNT_INFO.DASHBOARD_TYPE.TP_BANK} />,
    headerText: 'Dashboard',
  },
  {
    path: routes.userProfilePath,
    isPrivate: true,
    component: <UserProfile />,
    headerText: 'My Profile',
  },
  {
    path: routes.CHANGE_PASSWORD,
    isPrivate: true,
    component: <ChangePassword />,
    headerText: 'Reset Password',
  },
  {
    path: routes.TP_BANK,
    isPrivate: true,
    component: <TpBank />,
    headerText: 'TP Bank ',
  },
  {
    path: routes.SUPER_ADMIN,
    isPrivate: true,
    component: <UserManage type={ACCOUNT_INFO.ROLES.superAdmin} />,
    headerText: 'TP Bank Management',
  },
  {
    path: routes.MANAGER_MANAGE,
    isPrivate: true,
    component: <UserManage type={ACCOUNT_INFO.ROLES.manager} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.OPERATOR_MANAGE,
    isPrivate: true,
    component: <UserManage type={ACCOUNT_INFO.ROLES.operator} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.managerDetails,
    isPrivate: true,
    component: <UserDetail type={ACCOUNT_INFO.ROLES.manager} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.TP_BANK_Detail,
    isPrivate: true,
    component: <TpBankDetails />,
    headerText: 'TP Bank Details',
  },
  {
    path: routes.operatorDetails,
    isPrivate: true,
    component: <UserDetail type={ACCOUNT_INFO.ROLES.operator} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.ADD_MANAGER,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.manager} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.ADD_OPERATOR,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.operator} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.EDIT_MANAGER,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.manager} />,
    headerText: 'AESA User Management',
  },
  {
    path: routes.EDIT_OPERATOR,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.operator} />,
    headerText: 'AESA User Management',
  },
  //TP bank user management routes
  {
    path: routes.TP_BANK_SUPER_ADMIN,
    isPrivate: true,
    component: <TP_BANK_USER type={ACCOUNT_INFO.ROLES.tpBankSuperAdmin} />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.TP_BANK_MANAGER,
    isPrivate: true,
    component: <TP_BANK_USER type={ACCOUNT_INFO.ROLES.tpBankManager} />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.TP_BANK_OPERATOR,
    isPrivate: true,
    component: <TP_BANK_USER type={ACCOUNT_INFO.ROLES.tpBankOperator} />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.TP_BANK_USER_DETAILS,
    isPrivate: true,
    component: <UserDetailTPUser />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.ADD_SUPER_ADMIN,
    isPrivate: true,
    component: <AddSuperAdmin />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.EDIT_SUPER_ADMIN,
    isPrivate: true,
    component: <AddSuperAdmin />,
    headerText: 'TP Bank User Management',
  },
  {
    path: routes.ADD_TP_BANK,
    isPrivate: true,
    component: <AddTPBANK />,
    headerText: 'TP Bank',
  },
  {
    path: routes.EDIT_TP_BANK,
    isPrivate: true,
    component: <AddTPBANK isEdit />,
    headerText: 'TP Bank',
  },
  {
    path: routes.CREATE_CASE,
    isPrivate: true,
    component: <CreateCase />,
    headerText: 'Create Cases',
  },
  {
    path: routes.SEARCH_IN_CORE_SYSTEM,
    isPrivate: true,
    component: <SearchInCoreSystem />,
    headerText: 'Search In Core System',
  },
  {
    path: routes.CREATE_CASE_DETAIL,
    isPrivate: true,
    component: <CreateCaseDetail />,
    headerText: 'Create Cases',
  },
  {
    path: routes.CREATE_CASE_MANAGER,
    isPrivate: true,
    component: <AddCaseForm />,
    headerText: 'Create Cases',
  },
  {
    path: routes.CREATE_CASE_OPERATOR,
    isPrivate: true,
    component: <AddCaseForm />,
    headerText: 'Create Cases',
  },
  {
    path: routes.CASE_INBOX,
    isPrivate: true,
    component: <CaseInboxDetail />,
    headerText: 'Case Inbox',
  },
  //  core case details
  {
    path: routes.CORE_CASE,
    isPrivate: true,
    component: <CaseInboxDetail />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX_SENT,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX_RECEIVED,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX_CLOSED,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_EDIT_MANUAL,
    isPrivate: true,
    component: <AddCaseForm />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.MANAGE_NOTIFICATIONS,
    isPrivate: true,
    component: <ManageNotifications type={1} />,
    headerText: 'Notification Management',
  },
  {
    path: routes.MANAGE_TEMPLATES,
    isPrivate: true,
    component: <ManageNotifications type={2} />,
    headerText: 'Notification Management',
  },
  {
    path: routes.ADD_NOTIFICATION,
    isPrivate: true,
    component: <AddNotifications />,
    headerText: 'Add Notification',
  },
  {
    path: routes.AUDIT_LOG,
    isPrivate: true,
    component: <AuditLog />,
    headerText: 'Audit Log',
  },
  {
    path: routes.CONTACT_US,
    isPrivate: true,
    component: <ContactUs />,
    headerText: 'Contact Us',
  },
  {
    path: routes.TEMPLATES_DETAIL,
    isPrivate: true,
    component: <TemplateDetail />,
    headerText: 'Notification Management',
  },

  {
    path: routes.EDIT_TEMPLATE,
    isPrivate: true,
    component: <EditTemplate />,
    headerText: 'Notification Management',
  },
  {
    path: routes.ARCHIVED_TP_BANKS,
    isPrivate: true,
    component: <RetrieveTPBank />,
    headerText: 'Archived TP Banks',
  },
  {
    path: routes.ARCHIVED_TP_BANK_DETAILS,
    isPrivate: true,
    component: <TpBankDetails isArchived={true} />,
    headerText: 'Archived TP Bank Details',
  },
  // {
  //   path: routes.TRANSACTION_DETAILS,
  //   isPrivate: true,
  //   component: <TransactionDetails />,
  //   headerText: 'Transaction Details',
  // },
  // {
  //   path: routes.AUTH_TRANSACTION_DETAILS,
  //   isPrivate: true,
  //   component: <TransactionDetails isAuthTransaction={true} />,
  //   headerText: 'Auth Transaction Details',
  // },
];

// 1: public, 2: intermediate route, 3: private route

const getMyRoute = (
  isPrivate: boolean,
  path: string,
  component: any,
  isIntermediate = false,
  headerText?: string
) => {
  if (isPrivate) {
    return (
      <Route
        path={path}
        element={<PrivateContainer headerText={headerText}>{component}</PrivateContainer>}
      />
    );
  } else if (!isPrivate && isIntermediate) {
    return (
      <Route
        path={path}
        element={<LoginWrapper childComp={<IntermediateRoute>{component}</IntermediateRoute>} />}
      />
    );
  } else {
    return (
      <Route
        path={path}
        element={<LoginWrapper childComp={<PublicRoute>{component}</PublicRoute>} />}
      />
    );
  }
};

/**
 * Root router components
 * @param props parameter for Router function
 */
function Router() {
  const accessToken: any = getStorageItem('accessToken');
  if (accessToken !== undefined && accessToken !== null) {
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter
        //  Dynamic subroute through env
        basename={process.env.REACT_APP_SUB_DIRECTORY ? process.env.REACT_APP_SUB_DIRECTORY : '/'}
      >
        <Suspense
          fallback={
            <div>
              <span>Loading....</span>
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />

            {routesList.map(({ isPrivate, path, component, isIntermediate, headerText }) =>
              getMyRoute(isPrivate, path, component, isIntermediate, headerText)
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Router;
