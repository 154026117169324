import colorConstant from '../../constants/colorConstant';

export const styles = {
  mainMenuContainer: {
    width: '20%',
    height: '100vh',
    position: 'fixed',
    backgroundColor: colorConstant.amexBlue,
    paddingTop: '3.472vw',
    '& div': {
      // minWidth: 'unset',
      '& img': {
        margin: '0 1.042vw',
      },
    },
  },
  menuTextStyle: {
    '& span': {
      fontFamily: 'BentonSans',
      fontWeight: 700,
      fontSize: '1.111vw',
      lineHeight: '1.389vw',
      color: colorConstant.fontColorLiteBlue,
    },
  },

  menuLogo: {
    height: '1.250vw',
    width: '1.250vw',
  },
  iconColor: {
    color: colorConstant.fontColorLiteBlue,
  },
  mainContainer: {
    width: '20%',
  },
  menuButton: {
    marginRight: '1.250vw',
    '&.Mui-selected': {
      background: 'linear-gradient(270.01deg, #8FAED9 -45.09%, rgba(228, 240, 255, 0) 99.99%)',
      borderLeft: '0.208vw solid white ',
      borderRadius: '0 0.278vw 0.278vw 0',

      '& span': {
        color: colorConstant.amexWhite,
      },
    },
    '&:hover': {
      background: 'linear-gradient(270.01deg, #8FAED9 -45.09%, rgba(228, 240, 255, 0) 99.99%)',
      borderLeft: '0.208vw solid white ',
      borderRadius: '0 0.278vw 0.278vw 0',
      '& span': {
        color: colorConstant.amexWhite,
      },
    },
  },
  reactIcon: {
    // minWidth: 'unset',
    '& svg': {
      margin: '0 0.8vw',
      width: '1.4vw',
      height: '1.4vw',
    },
  },
};
