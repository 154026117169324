const styles = {
  universalComp_mainContainer: {
    width: '100%',
    height: '100vh',
    overflow: 'scroll',
    background: '#f5f7fb',
    '& .MuiBackdrop-root': {
      zIndex: '1400 !important',
    },
  },

  universalComp_menuContainer: {
    display: 'flex',
  },
  rightContainer: {
    width: '76% !important',
    padding: '0 2%',
    backgroundColor: 'rgb(245 247 253)',
  },

  universalComp_contentContainer: {},
};

export default styles;
