import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReducersState } from '../../redux/rootReducer';
import ACTION_NAMES from '../../constants/actionNames';
import { getCaseInboxListing } from './services';
import Helpers from '../../helpers';

const useCaseInboxHelper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const paramPath: any = location.pathname;

  const id = Helpers.getParameterByName('id');
  const coreId = Helpers.getParameterByName('coreId');
  const searchCase = Helpers.getParameterByName('searchCase');
  const searchOn = Helpers.getParameterByName('searchOn');
  const caseType = Helpers.getParameterByName('caseType');

  const {
    startDate,
    endDate,
    isFilter,
    SENumber,
    SEName,
    caseListCount,
    caseListSearch,
    listType,
    messageType,
    caseListingData,
    caseListPage,
    caseListPageCount,
    caseTransactionListing,
    case_id,
    case_caseId,
    case_startingCardNumber,
    case_endingCardNumber,
    case_SENumber,
    case_SEName,
    case_merchantCategoryCode,
    case_ARNNumber,
    case_terminalNumber,
    case_transactionDate,
    case_transactionTime,
    case_transactionAmount,
    case_presentmentAmount,
    case_transactionId,
    case_transactionType,
    case_approvalCode,
    case_caseType,
    case_sent,
    case_messageType,
    case_itemizedDocumentType,
    case_coreCase_amount,
    case_messageReasonCode,
    isActionButton,
    caseDetailsComplete,
    caseDetailsIsValid,
    SEerrorMessage,
    isSEerror,
    case_referenceNumber,
    isCountValid,
    case_retailerId,
    isActionTaken,
  } = useSelector((state: ReducersState) => state.CaseInboxReducer);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [openRetrievalRequestModal, setOpenRetrievalRequestModal] = useState(false);
  const [openFirstChargebackModal, setOpenFirstChargebackModal] = useState(false);
  const [openFinalChargebackModal, setOpenFinalChargebackModal] = useState(false);
  const [openCaseListingDownloadModal, setCaseListingDownloadModal] = useState(false);
  const [openCloseCaseModal, setOpenCloseCaseModal] = useState(false);
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false);
  const [openAcceptFulfillmentModal, setOpenAcceptFulfillmentModal] = useState(false);

  const [openAcceptSecondPresentmentModal, setOpenAcceptSecondPresentmentModal] = useState(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch({
      type: ACTION_NAMES.CASE_INBOX,
      payload: {
        caseListPage: value,
      },
    });
    dispatch(getCaseInboxListing());
  };

  const handleSearch = (event: any) => {
    dispatch({
      type: ACTION_NAMES.CASE_INBOX,
      payload: {
        caseListSearch: event.target.value,
        caseListPage: 1,
      },
    });
  };

  const handleApplyFilter = () => {
    dispatch({
      type: ACTION_NAMES.CASE_INBOX,
      payload: {
        caseListPage: 1,
      },
    });
    dispatch(getCaseInboxListing());
  };

  const handleFilterReset = () => {
    dispatch({
      type: ACTION_NAMES.CASE_INBOX,
      payload: {
        startDate: null,
        ApiEndDate: null,
        endDate: null,
        messageType: 0,
        SENumber: '',
        SEName: '',
      },
    });
    dispatch(getCaseInboxListing());
  };

  const getMessageType = (messageType: number) => {
    if (messageType === 1) return 'Retrieval Request';
    else if (messageType === 2) return 'First Chargeback';
    else if (messageType === 3) return 'Final Chargeback';
    else if (messageType === 4) return 'First Presentment';
    else if (messageType === 5) return 'Not Fulfilled';
    else if (messageType === 6) return 'Not Second Presentment';
    else if (messageType === 7) return 'Accept Fulfillment(Closed)';
    else if (messageType === 8) return 'Accept Second Presentment(Closed)';
    else if (messageType === 9) return 'Closed';
    else if (messageType === 10) return '6302 - Not Fulfilling';
    else if (messageType === 11) return 'Accept First Chargeback';
    else if (messageType === 12) return 'Fulfillment';
    else if (messageType === 13) return 'Second Presentment';
    else return '-';
  };

  const getTotalDocs = (totalDocs: number | null) => {
    if (totalDocs !== null) return totalDocs;
    else return '-';
  };

  const getAmount = (amount: number | null | string) => {
    if (amount !== null) return `${amount} SAR`;
    else return '-';
  };

  return {
    dispatch,
    navigate,
    location,
    paramPath,
    openDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openRetrievalRequestModal,
    setOpenRetrievalRequestModal,
    openFirstChargebackModal,
    setOpenFirstChargebackModal,
    openFinalChargebackModal,
    setOpenFinalChargebackModal,
    openAcceptSecondPresentmentModal,
    setOpenAcceptSecondPresentmentModal,
    setOpenAcceptFulfillmentModal,
    openAcceptFulfillmentModal,
    handleChange,
    openCaseListingDownloadModal,
    setCaseListingDownloadModal,

    startDate,
    endDate,
    isFilter,
    SENumber,
    SEName,
    caseListCount,
    caseListSearch,
    listType,
    messageType,
    caseListingData,
    caseListPage,
    caseListPageCount,
    caseTransactionListing,
    case_id,
    case_caseId,
    case_startingCardNumber,
    case_endingCardNumber,
    case_SENumber,
    case_SEName,
    case_merchantCategoryCode,
    case_ARNNumber,
    case_terminalNumber,
    case_transactionDate,
    case_transactionTime,
    case_transactionAmount,
    case_presentmentAmount,
    case_transactionId,
    case_transactionType,
    case_approvalCode,
    case_caseType,
    case_itemizedDocumentType,
    case_coreCase_amount,
    case_messageReasonCode,
    case_sent,
    handleSearch,
    handleApplyFilter,
    handleFilterReset,
    openCloseCaseModal,
    setOpenCloseCaseModal,
    openTransactionDetailModal,
    setOpenTransactionDetailModal,
    getMessageType,
    case_messageType,
    getTotalDocs,
    getAmount,
    isActionButton,
    id,
    coreId,
    searchCase,
    searchOn,
    caseType,
    caseDetailsComplete,
    caseDetailsIsValid,
    SEerrorMessage,
    isSEerror,
    case_referenceNumber,
    isCountValid,
    case_retailerId,
    isActionTaken,
  };
};

export default useCaseInboxHelper;
