import React from 'react';
import { toast } from 'react-toastify';
import { CheckCircle } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';

// local import
import AlertBox from './Alert';

const commonErr = 'OOPS! something went wrong!';

const toastList = new Set();
const MAXIMUM_TOAST = 1;

const getAutoCloseTimeInSeconds = () => {
  const alertCloseTimeInSeconds = process.env.REACT_APP_ALERT_CLOSE_IN_SECONDS || '10';
  const parsedSecondsForAlertClose = parseInt(alertCloseTimeInSeconds);

  if (parsedSecondsForAlertClose) {
    return parsedSecondsForAlertClose;
  } else {
    return 10;
  }
};

const AUTOCLOSE_TIME_IN_SECONDS = getAutoCloseTimeInSeconds() * 1000;

const showAlert = (type: number, message: string = commonErr) => {
  if (toast.error === undefined) {
    // toast.configure({
    //   autoClose: 4000,
    //   draggable: false,
    //   newestOnTop: true,
    //   position: "top-right",
    // });
  }

  switch (type) {
    case 1: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.success(
          <AlertBox
            message={message}
            icon={<CheckCircle color="secondary" />}
            close={() => {
              toastList.delete(id);
            }}
          />,
          {
            onClose: () => toastList.delete(id),
            autoClose: AUTOCLOSE_TIME_IN_SECONDS,
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 2: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.error(
          <AlertBox
            message={message}
            icon={<CheckCircle color="secondary" />}
            close={() => {
              toastList.delete(id);
            }}
          />,
          {
            onClose: () => toastList.delete(id),
            autoClose: AUTOCLOSE_TIME_IN_SECONDS,
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 3: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.info(
          <AlertBox
            message={message}
            icon={<CheckCircle color="secondary" />}
            close={() => {
              toastList.delete(id);
            }}
          />,
          {
            onClose: () => toastList.delete(id),
            autoClose: AUTOCLOSE_TIME_IN_SECONDS,
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 4: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.warning(
          <AlertBox
            message={message}
            icon={<CheckCircle color="secondary" />}
            close={() => {
              toastList.delete(id);
            }}
          />,
          {
            onClose: () => toastList.delete(id),
            autoClose: AUTOCLOSE_TIME_IN_SECONDS,
          }
        );
        toastList.add(id);
      }
      break;
    }
  }
};

export default showAlert;
