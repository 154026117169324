import { Button, ButtonProps } from '@mui/material';
import styles from './style';

export type LinkButtonProps = {
  title: string;
  externalStyles?: object;
};

const LinkButton = ({ title, externalStyles, ...otherProps }: LinkButtonProps & ButtonProps) => {
  return (
    <Button sx={{ ...styles.btn, ...externalStyles }} {...otherProps}>
      {title}
    </Button>
  );
};

export default LinkButton;
