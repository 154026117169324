import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class DashboarState {
  type: number = 1;
  listingType: number = 2;
  listingData = [];
  userListCount: number = 0;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListPageCount: number = 1;

  // dashboard items TP
  activeTpBankAdmin = null;
  activeTpBankManager = null;
  activeTpBankOperator = null;
  blockTpBankAdmin = null;
  blockTpBankManager = null;
  blockTpBankOperator = null;
  adminRequestPendingTpBank = null;
  activebank = null;
  blockbank = null;
  requestPendingBank = null;
  topBanks: any = [];

  // dashboard items AESA
  activeManger = null;
  activeOperator = null;
  blockManger = null;
  blockOperator = null;

  bankId: string = '';
  bankName: string = '';
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = null;
  isFilter: boolean = false;
  isDownloadFilter: boolean = false;
  bankDocumentId: string = '';
  isBankIdValid: boolean = false;

  //case results
  activeChargeBack = null;
  activeFinalChargeBack = null;
  activeRetrievalRequest = null;
  caseClosed = null;
  caseRegisterInDay = null;
  pendingChargeBackFifteen = null;
  pendingChargeBackFive = null;
  pendingChargeBackTen = null;
  pendingFinalChargeBackFifteen = null;
  pendingFinalChargeBackFive = null;
  pendingFinalChargeBackTen = null;
  pendingRetrievalFifteen = null;
  pendingRetrievalFive = null;
  pendingRetrievalTen = null;

  bankIdApplied: string = '';
  bankNameApplied: string = '';
  bankDocumentIdApplied: string = '';
}

export const DashboardReducer = (state: DashboarState = new DashboarState(), action: any = {}) => {
  if (action.type === ACTION_NAMES.DASHBOARD) {
    return { ...state, ...action.payload };
  }
  return state;
};
