import moment from 'moment';
import showAlert from '../../components/alert';
import ACTION_NAMES from '../../constants/actionNames';
import apiResponses from '../../constants/apiResponseCodes';
import ENDPOINTS from '../../constants/endpoints';
import routes from '../../constants/routes';
import { checkResponseStatus } from '../../helpers';
import { API } from '../../middlewares';
import { updateGlobalLoader } from '../../redux/globalActions';

const getAmount = (tabState: number, transAmount: any, cbAmt: any, finalCbAmt: any) => {
  if (tabState === 0) {
    return transAmount;
  }
  if (tabState === 1) {
    return cbAmt;
  } else if (tabState === 2) {
    return transAmount;
  }
};

const getDocs = (tabState: number, firstDoc: any, secDoc: any, thridDoc: any) => {
  if (tabState === 0 && firstDoc.length > 0) {
    return firstDoc;
  } else if (tabState === 1 && secDoc.length > 0) {
    return secDoc;
  } else if (tabState === 2 && thridDoc.length > 0) {
    return thridDoc;
  }
};

/**
 * @definition post data of the form to add TP bank
 * @param values for getting updated values of the form
 * @param setSubmitting for disabling button until api gives a response
 * @returns success or error
 */
export const addCaseManually =
  (values: any, setSubmitting: any, navigate: any, tabState: any) =>
  (dispatch: any, getState: any) => {
    dispatch(updateGlobalLoader(true));
    const {
      tabState,
      retrievalFiles,
      firstCbFiles,
      finalCbFiles,
      filePath,
      transDate,
      bankDocumentId,
    } = getState().CreateCaseReducer;
    const {
      cardNumber,
      // transDate,
      seNum,
      transTime,
      seName,
      transAmount,
      merchantCode,
      presentmentAmount,
      arnNo,
      aprCode,
      terminalNo,
      transId,
      transType,
      retrievalReason,
      cbReason,
      finalCb,
      comment,
      cbAmt,
      finalCbAmt,
      docType,
      referenceNumber,
      retailerId,
    } = values;

    const startNo = cardNumber?.substring(0, 4);
    const endNo = cardNumber?.substring(11);
    const retrievalMSG = retrievalReason?.substring(0, 4);
    const chargeBackFirst = cbReason?.substring(0, 4);
    const chargeBackFinal = finalCb?.substring(0, 4);
    const itemizedDoc = docType?.substring(0, 2);
    // const transTimeMain = moment(transTime).format('hh:mm A');
    setSubmitting(true);

    const dataToSend = {
      startingCardMemberNumber: startNo,
      endingCardMemberNumber: endNo,
      transactionDate: moment(transDate).format('YYYY-MM-DD'),
      SENumber: seNum,
      bankDocumentId,
      ...(transTime !== null &&
        transTime !== '  :  :  :  ' &&
        transTime !== '' && { transactionTime: transTime }),
      SEName: seName,
      transactionAmount: transAmount,
      merchantCategoryCode: merchantCode,
      presentmentAmount: presentmentAmount,
      ARNNumber: arnNo,
      approvalCode: aprCode,
      terminalNumber: terminalNo,
      ...(transId && { transactionId: transId }),
      ...(retailerId && { retailerId }),

      // caseId,
      transactionCurrency: 'SAR',
      transactionType: transType,
      messageType: tabState + 1,
      messageReasonCode: getDocs(tabState, retrievalMSG, chargeBackFirst, chargeBackFinal),
      ...(comment !== undefined &&
        comment !== null &&
        comment.length > 0 && { description: comment }),
      amount: getAmount(tabState, transAmount, cbAmt, finalCbAmt),
      // amount: cbAmt | finalCbAmt,
      ...(((retrievalFiles !== undefined && retrievalFiles !== null && retrievalFiles.length > 0) ||
        (firstCbFiles !== undefined && firstCbFiles !== null && firstCbFiles.length > 0) ||
        (finalCbFiles !== undefined && finalCbFiles !== null && finalCbFiles.length > 0)) && {
        documents: filePath,
      }),
      // documents: retrievalFiles | firstCbFiles | finalCbFiles,
      ...(itemizedDoc !== undefined &&
        itemizedDoc !== null &&
        itemizedDoc.length > 0 && { itemizedDocumentType: itemizedDoc }),
      ...(referenceNumber && { referenceNumber }),
    };

    API.post(ENDPOINTS.createCase, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          navigate(routes.CASE_INBOX_SENT);
        } else {
          showAlert(2, response?.data?.message);
        }
        dispatch(updateGlobalLoader(false));
        setSubmitting(false);
      })
      .catch((error) => {
        const { response } = error;

        if (response && response.data.statusCode === apiResponses.conflict) {
          const respData = response.data.data;

          Object.entries(respData).forEach((data: any) => {
            if (data[0] === 'referenceNumber') {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  referenceNumberData: {
                    value: referenceNumber,
                    errorText: data[1],
                    isExists: false,
                  },
                },
              });
            }

            if (data[0] === 'ARNNumber') {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  arnNumberData: {
                    value: arnNo,
                    errorText: data[1],
                    isExists: false,
                  },
                },
              });
            }

            if (data[0] === 'TransactionId') {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  transIdData: {
                    value: transId,
                    errorText: data[1],
                    isExists: false,
                  },
                },
              });
            }
          });
        }
        showAlert(2, response?.data?.message);
        setSubmitting(false);
        dispatch(updateGlobalLoader(false));
      });
  };

/**
 * @definition for uploading documents
 * @param acceptedFiles
 * @returns filenames
 */
export const readDoc =
  (acceptedFiles: any, fileNames: any, newFile: any, tabState: any) =>
  (dispatch: any, getState: any) => {
    dispatch(updateGlobalLoader(true));

    const { filePath } = getState().CreateCaseReducer;

    const formData: any = new FormData();
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append('file', acceptedFiles[i]);
    }

    if (formData.get('file') === null) {
      dispatch(updateGlobalLoader(false));
      return showAlert(2, 'This file is not supported');
    } else {
      API.post(ENDPOINTS.createCaseDocUpload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(function (response: any) {
          const oldFile = [...fileNames, ...newFile];
          if (checkResponseStatus(response)) {
            if (tabState === 0) {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  retrievalFiles: oldFile,
                },
              });
            } else if (tabState === 1) {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  firstCbFiles: oldFile,
                },
              });
            } else if (tabState === 2) {
              dispatch({
                type: ACTION_NAMES.CREATE_CASE,
                payload: {
                  finalCbFiles: oldFile,
                },
              });
            } else {
              showAlert(2, 'Something went wrong');
              return;
            }

            dispatch({
              type: ACTION_NAMES.CREATE_CASE,
              payload: {
                filePath: [...filePath, ...response?.data?.data?.filePath],
              },
            });
            dispatch(updateGlobalLoader(false));
          } else {
            showAlert(2, response?.data?.message);
          }
        })
        .catch((error) => {
          const { response } = error;
          dispatch(updateGlobalLoader(false));
          showAlert(2, response?.data?.message);
        });
    }
  };

/**
 * @definition for deleting documents
 * @param fileLink
 * @param type
 */
export const deleteDoc = (fileLink: string, type: number) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  const params = `?link=${fileLink}&type=${type}`;

  API.delete(`${ENDPOINTS.createCaseDocUpload}${params}`)
    .then((response: any) => {
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

export const checkBankSeNumber = (seNum: any, setFieldError?: Function) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  const params = `?SENumber=${seNum}`;

  API.get(`${ENDPOINTS.createCaseBankSE}${params}`)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        dispatch({
          type: ACTION_NAMES.CREATE_CASE,
          payload: {
            seNumberData: {
              value: seNum,
              errorText: '',
              isExists: true,
            },
            bankDocumentId: response?.data?.data?.bankDocumentId,
          },
        });
        dispatch(updateGlobalLoader(false));
      }

      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;

      dispatch({
        type: ACTION_NAMES.CREATE_CASE,
        payload: {
          seNumberData: {
            value: seNum,
            errorText: response?.data?.message,
            isExists: false,
          },
        },
      });
      dispatch(updateGlobalLoader(false));
    });
};

export const getValidationMsg = (type: any) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  const params = `?type=${type}`;

  API.get(`${ENDPOINTS.createCaseValidationMsg}${params}`)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        if (type === 1) {
          dispatch({
            type: ACTION_NAMES.CREATE_CASE,
            payload: {
              retrievalMsg: response?.data?.data?.validationMsg,
            },
          });
        } else if (type === 2) {
          dispatch({
            type: ACTION_NAMES.CREATE_CASE,
            payload: {
              chargeBackReason: response?.data?.data?.validationMsg,
            },
          });
        } else if (type === 3) {
          const sortedList = Object.values(response?.data?.data?.validationMsg).sort(function (
            a: any,
            b: any
          ) {
            const c = a?.substring(0, 2);
            const d = b?.substring(0, 2);
            return c - d;
          });
          dispatch({
            type: ACTION_NAMES.CREATE_CASE,
            payload: {
              itemizedDocMsg: sortedList,
            },
          });
        }
        dispatch(updateGlobalLoader(false));
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      // const { response } = error;

      dispatch(updateGlobalLoader(false));
    });
};

// export const checkARNNumber = (arnNumber: any, caseARN: any, path: any) => (dispatch: any) => {
//   dispatch(updateGlobalLoader(true));

//   const params = `?arnNumber=${arnNumber}`;

//   API.get(`${ENDPOINTS.createCaseARNExists}${params}`)
//     .then((response: any) => {
//       if (checkResponseStatus(response)) {

//         dispatch({
//           type: ACTION_NAMES.CREATE_CASE,
//           payload: {
//             arnNumberData: {
//               value: arnNumber,
//               errorText: '',
//               isExists: true,
//             },
//           },
//         });
//         dispatch(updateGlobalLoader(false));
//       }
//       dispatch(updateGlobalLoader(false));
//     })
//     .catch((error) => {
//       const { response } = error;
//       // setFieldError('arnNo', response?.data?.message);
//       // dispatch({
//       //   type: ACTION_NAMES.CREATE_CASE,
//       //   payload: {
//       //     isARNExists: false,
//       //   },
//       // });

//       if (path === routes.CASE_EDIT_MANUAL && arnNumber === caseARN) {
//         dispatch({
//           type: ACTION_NAMES.CREATE_CASE,
//           payload: {
//             arnNumberData: {
//               value: arnNumber,
//               errorText: '',
//               isExists: true,
//             },
//           },
//         });
//       } else {
//         dispatch({
//           type: ACTION_NAMES.CREATE_CASE,
//           payload: {
//             arnNumberData: {
//               value: arnNumber,
//               errorText: response?.data?.message,
//               isExists: false,
//             },
//           },
//         });
//       }
//       // showAlert(2, response?.data?.message);
//       dispatch(updateGlobalLoader(false));
//     });
// };

// export const checkReferenceNumber =
//   (refNumber: any, caseRef: any, path: any) => (dispatch: any) => {
//     dispatch(updateGlobalLoader(true));

//     const params = `?referenceNumber=${refNumber}`;

//     API.get(`${ENDPOINTS.createCaseRefExists}${params}`)
//       .then((response: any) => {
//         if (checkResponseStatus(response)) {
//           dispatch({
//             type: ACTION_NAMES.CREATE_CASE,
//             payload: {
//               referenceNumberData: {
//                 value: refNumber,
//                 errorText: '',
//                 isExists: true,
//               },
//             },
//           });
//           dispatch(updateGlobalLoader(false));
//         }
//         dispatch(updateGlobalLoader(false));
//       })
//       .catch((error) => {
//         const { response } = error;

//         if (path === routes.CASE_EDIT_MANUAL && refNumber === caseRef) {
//           dispatch({
//             type: ACTION_NAMES.CREATE_CASE,
//             payload: {
//               referenceNumberData: {
//                 value: refNumber,
//                 errorText: '',
//                 isExists: true,
//               },
//             },
//           });
//         } else {
//           dispatch({
//             type: ACTION_NAMES.CREATE_CASE,
//             payload: {
//               referenceNumberData: {
//                 value: refNumber,
//                 errorText: response?.data?.message,
//                 isExists: false,
//               },
//             },
//           });
//         }

//         dispatch(updateGlobalLoader(false));
//       });
//   };

export const editCaseManually =
  (values: any, setSubmitting: any, paramId: any, navigate: any) =>
  (dispatch: any, getState: any) => {
    dispatch({
      type: ACTION_NAMES.IS_LOADING,
      payload: true,
    });

    const { case_transactionCurrency } = getState().CaseInboxReducer;
    const { transDate } = getState().CreateCaseReducer;

    const {
      cardNumber,
      seNum,
      transTime,
      seName,
      transAmount,
      merchantCode,
      presentmentAmount,
      arnNo,
      aprCode,
      terminalNo,
      transId,
      transType,
      referenceNumber,
      retailerId,
    } = values;

    const params = `?id=${paramId}`;

    const startNo = cardNumber?.substring(0, 4);
    const endNo = cardNumber?.substring(11);

    const dataToSend = {
      ...(startNo !== undefined && { startingCardMemberNumber: startNo }),
      ...(endNo !== undefined && { endingCardMemberNumber: endNo }),
      ...(transDate !== undefined && { transactionDate: moment(transDate).format('YYYY-MM-DD') }),
      ...(seNum !== undefined && { SENumber: seNum }),
      ...(transTime !== undefined && { transactionTime: transTime }),
      ...(seName !== undefined && { SEName: seName }),
      ...(transAmount !== undefined && { transactionAmount: transAmount }),
      ...(merchantCode !== undefined && { merchantCategoryCode: merchantCode }),
      ...(presentmentAmount !== undefined && {
        presentmentAmount: presentmentAmount,
      }),
      ...(arnNo !== undefined && { ARNNumber: arnNo }),
      ...(aprCode !== undefined && { approvalCode: aprCode }),
      ...(terminalNo !== undefined && { terminalNumber: terminalNo }),
      ...(transId !== undefined && { transactionId: transId }),
      ...(retailerId !== undefined && { retailerId }),

      ...(transType !== undefined && { transactionType: transType }),
      ...(transAmount !== undefined && {
        transactionCurrency: case_transactionCurrency,
      }),
      ...(referenceNumber !== undefined && { referenceNumber }),
    };

    API.put(`${ENDPOINTS.createCaseEdit}${params}`, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          setSubmitting(false);
          navigate(routes.CASE_INBOX_SENT);
          dispatch(updateGlobalLoader(false));
        } else {
          dispatch(updateGlobalLoader(false));
          setSubmitting(false);
        }
      })
      .catch((error) => {
        const { response } = error;
        showAlert(2, response?.data?.message);
        dispatch(updateGlobalLoader(false));
      });
  };

export const getApiCaseListing = () => (dispatch: any, getState: any) => {
  dispatch(updateGlobalLoader(true));

  const {
    userListPage,
    userListLimit,
    userListSearch,
    seNumber,
    seName,
    messageType,
    startDate,
    ApiEndDate,
  } = getState().CreateCaseReducer;
  const dataToSend = {
    params: {
      page: userListPage,
      limit: userListLimit,
      ...(userListSearch.length && { search: userListSearch }),
      ...(seNumber.length && { SENumber: seNumber }),
      ...(seName.length && { SEName: seName }),
      ...(messageType !== null && messageType !== 0 && { messageType: messageType }),
      ...(startDate !== null && { fromDate: startDate }),
      ...(ApiEndDate !== null && { toDate: ApiEndDate }),
    },
  };

  API.get(ENDPOINTS.coreCaseListing, dataToSend)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;

        dispatch({
          type: ACTION_NAMES.CREATE_CASE,
          payload: {
            listingData: data?.data?.data,
            userListCount: data?.data?.count,
            userListPage: data?.data?.page,
            userListPageCount: data?.data?.pageCount,
          },
        });
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error: any) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

export const validateAndProcess =
  (navigate: any, setOpenValidate: any, singleCaseId?: string | null, values?: any) =>
  (dispatch: any, getState: any) => {
    dispatch(updateGlobalLoader(true));
    const { selectedIds, filePath, retrievalFiles } = getState().CreateCaseReducer;
    let dataToSend = {};
    dataToSend = {
      id: selectedIds,
    };
    if (singleCaseId) {
      dataToSend = {
        id: [singleCaseId],
        ...(values?.comment !== undefined &&
          values?.comment !== null &&
          values?.comment?.length > 0 && { description: values?.comment }),
        ...(retrievalFiles !== undefined &&
          retrievalFiles !== null &&
          retrievalFiles.length > 0 && { documents: filePath }),
      };
    }

    API.post(ENDPOINTS.codeCaseValidate, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          navigate(routes.CASE_INBOX_SENT);
        } else {
          dispatch({
            type: ACTION_NAMES.CASE_INBOX,
            payload: {
              isSEerror: true,
              EerrorMessage: response?.data?.message,
            },
          });
          dispatch({
            type: ACTION_NAMES.CREATE_CASE,
            payload: {
              retrievalFiles: [],
            },
          });
        }
        setOpenValidate(false);
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        // dispatch({
        //   type: ACTION_NAMES.CASE_INBOX,
        //   payload: {
        //     isSEerror: true,
        //     SEerrorMessage: response?.data?.message,
        //   },
        // });
        showAlert(2, response.message);
        dispatch({
          type: ACTION_NAMES.CREATE_CASE,
          payload: {
            retrievalFiles: [],
          },
        });
        setOpenValidate(false);
        dispatch(updateGlobalLoader(false));
      });
  };

export const validateAndProcessBySearchCase =
  (navigate: any, completeCaseData: any, setOpenValidate: any, values?: any) =>
  (dispatch: any, getState: any) => {
    dispatch(updateGlobalLoader(true));
    // const { selectedIds } = getState().CreateCaseReducer;
    const { filePath, retrievalFiles } = getState().CreateCaseReducer;
    let dataToSend = {};

    dataToSend = {
      searchCaseDetails: [completeCaseData],
      ...(values?.comment !== undefined &&
        values?.comment !== null &&
        values?.comment?.length > 0 && { description: values?.comment }),
      ...(retrievalFiles !== undefined &&
        retrievalFiles !== null &&
        retrievalFiles.length > 0 && { documents: filePath }),
    };

    API.post(ENDPOINTS.codeCaseSearchValidate, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          showAlert(1, data.message);
          navigate(routes.CASE_INBOX_SENT);
        } else {
          dispatch({
            type: ACTION_NAMES.CASE_INBOX,
            payload: {
              isSEerror: true,
              SEerrorMessage: response?.data?.message,
            },
          });
        }
        setOpenValidate(false);
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        if (response?.data?.message) {
          showAlert(2, response.message);
          // dispatch({
          //   type: ACTION_NAMES.CASE_INBOX,
          //   payload: {
          //     isSEerror: true,
          //     SEerrorMessage: response?.data?.message,
          //   },
          // });
        }
        setOpenValidate(false);
        dispatch(updateGlobalLoader(false));
      });
  };
