import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import { COLORS } from '../../../constants/colors';

type TextFieldWrapperProps = {
  name: string;
} & TextFieldProps;

const StyledTextField = styled(TextField)<TextFieldWrapperProps>(({ theme }) => ({
  '& label.Mui-focused': {
    color: COLORS.inputText,
  },
  '& .MuiInput-underline:after': {},
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.278vw',
    height: 'inherit',
    font: 'normal normal 550 1.250vw/3.181vw BentonSans',
    color: COLORS.inputText,
    '& fieldset': {
      color: COLORS.inputText,
      border: `0.104vw solid ${COLORS.inputBorder}`,
    },
    '&:hover fieldset': {
      color: COLORS.inputText,
      border: `0.104vw solid ${COLORS.inputBorder}`,
    },
    '&.Mui-focused fieldset': {
      color: COLORS.inputText,
      border: `0.104vw solid ${COLORS.inputBorder}`,
    },
  },
}));

const TextFieldWrapper = ({ name, ...otherProps }: TextFieldWrapperProps) => {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <StyledTextField {...configTextField} />;
};

export default TextFieldWrapper;
